import {Naja} from 'naja';
import {Extension} from 'naja/dist/Naja';

export default class CompanyContactsStickExtension implements Extension {
	
	public initialize(naja: Naja): void {
		let section: HTMLElement = document.querySelector('.detail-cnt .contacts');
		
		if (section !== null) {
			window.addEventListener('scroll', function() {
				const elementBottom = section.getBoundingClientRect().top + section.offsetHeight;
				
				if (elementBottom > 0) {
					document.querySelectorAll('.js-contact-bar').forEach(el => el.classList.remove('active'));
				} else {
					document.querySelectorAll('.js-contact-bar').forEach(el => el.classList.add('active'));
				}
			});
		}
	}
	
}
