import {Extension} from "naja/dist/Naja";
import {Naja} from "naja";
import {AfterUpdateEvent, BeforeUpdateEvent} from "naja/dist/core/SnippetHandler";

export default class FormRefreshExtension implements Extension {
	private naja: Naja;
	private timeout: NodeJS.Timeout;

	public initialize(naja: Naja): void {
		this.naja = naja;
		this.handleRefreshLazy = this.handleRefreshLazy.bind(this);
		this.handleRefresh = this.handleRefresh.bind(this);

		this.initModule(document.querySelector('body'));

		naja.snippetHandler.addEventListener('afterUpdate', (event: AfterUpdateEvent) => {
			this.initModule(event.detail.snippet);
		});
		naja.snippetHandler.addEventListener('beforeUpdate', (event: BeforeUpdateEvent) => {
			this.destroyModule(event.detail.snippet);
		});
	}

	private initModule(snippet: Element) {
		snippet.querySelectorAll('[data-form-refresh]').forEach(input => {
			let form = input.closest('form[data-form-refresh-url]');
			if (form !== null) {
				input.addEventListener('input', this.handleRefreshLazy);
				input.addEventListener('change', this.handleRefreshLazy);
			}
		});
	}

	private destroyModule(snippet: Element) {
		snippet.querySelectorAll('[data-form-refresh]').forEach(input => {
			input.removeEventListener('input', this.handleRefreshLazy);
			input.removeEventListener('change', this.handleRefreshLazy)
		});
	}

	private handleRefreshLazy(event: Event) {
		if (this.timeout !== null) {
			clearTimeout(this.timeout);
			this.timeout = null;
		}
		if (event.type === 'input') {
			this.timeout = setTimeout(() => this.handleRefresh(event), 500);
		} else {
			this.handleRefresh(event);
		}
	}

	private handleRefresh(event: Event) {
		let input = <Element>event.target;
		let form = input.closest('form[data-form-refresh-url]');
		if (form instanceof HTMLFormElement) {
			let url = new URL(form.getAttribute('data-form-refresh-url'), location.href);
			let formData = new FormData(form);
			let onlyRefreshData = form.hasAttribute('data-form-refresh-data-only');

			url.searchParams.append('_refreshedBy', input.getAttribute('data-form-refresh'));
			formData.forEach((value, key) => {
				if (key === '_do') {
					return;
				}
				if (onlyRefreshData) {
					let sourceInput = form.querySelector('[name="' + key + '"]');
					if (sourceInput !== null && !sourceInput.hasAttribute('data-form-refresh')) {
						return;
					}
				}
				if (value !== null && value !== undefined) {
					key = key.replace(/([^\[]+)/, 'data[$1]');
					url.searchParams.append(key, String(value));
				}
			});

			// noinspection JSIgnoredPromiseFromCall
			this.naja.makeRequest('GET', url, null, {
				history: false,
				transitionBlock: input.closest('.transition-auto'),
			});
		}
	}

}
