import {Naja} from 'naja';
import {Extension} from "naja/dist/Naja";
import {AfterUpdateEvent} from "naja/dist/core/SnippetHandler";

export default class AlertsExtension implements Extension {
	private timeoutMin = 2000;
	private timeoutPerWord = 300;

	initialize(naja: Naja): void {
		this.initModule(document.querySelector('body'));

		naja.snippetHandler.addEventListener('afterUpdate', (event: AfterUpdateEvent) => {
			this.initModule(event.detail.snippet);
		});
	}

	private initModule(snippet: Element) {
		snippet.querySelectorAll('.alerts .alert:not(.alert-dismissible)').forEach(el => {
			let timeout = this.setTimeoutHide(el);

			el.addEventListener('mouseover', () => {
				clearTimeout(timeout);
			});
		});
	}

	private setTimeoutHide(el: Element): ReturnType<typeof setTimeout> {
		let ms = 0;
		if (el.textContent === null) {
			ms = this.timeoutMin;
		} else {
			let text = el.textContent.replace(/\W+/g, ' ').trim();
			let words = text.split(' ').length;
			ms = Math.max(words * this.timeoutPerWord, this.timeoutMin);
		}

		return setTimeout(() => {
			el.classList.remove('show');
		}, ms);
	}
}
