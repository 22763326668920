
export default class Listing {
	private readonly container: Element;

	public constructor(container: Element) {
		this.container = container;

		this.handleScroll = this.handleScroll.bind(this);

		window.addEventListener('scroll', this.handleScroll);
	}

	public destroy(): void {
		window.removeEventListener('scroll', this.handleScroll);
	}

	private handleScroll(): void {
		const rect = this.container.getBoundingClientRect();
		if (rect.top <= 0) {
			this.container.classList.add('compact');
		} else {
			this.container.classList.remove('compact');
		}
	}

}
