import AbstractCompanyMapItineraryComponentExtension from "./AbstractCompanyMapItineraryComponentExtension";

// objekt SMap se natáhne z externího skriptu v šabloně
declare var SMap: any;

export default class CompanyMapItineraryClientComponentExtension extends AbstractCompanyMapItineraryComponentExtension<HTMLElement> {

	protected getSelector(): string {
		return '.init-company-map-itinerary';
	}

	protected createComponent(el: HTMLElement) {
		let targetAddress = el.dataset['targetAddress'];
		
		if (!targetAddress) {
			console.error('No address provided.');
			
			return;
		}
		
		let self = this;
		let inputElementSuggestion = el.querySelector<HTMLInputElement>('input.address-suggestion');
		let infoText = el.querySelector('.directions-text');
		let button = el.querySelector('button');
		let resultUrl = null;
		
		button.addEventListener('click', function() {
			if (resultUrl === null) {
				return false;
			}
			
			window.open(resultUrl, '_blank');
		});
		
		inputElementSuggestion.addEventListener('blur', function() {
			if (this.value === '') {
				button.disabled = true;
			}
		});
		
		let suggest = this.createSuggest(inputElementSuggestion);
		
		suggest.addListener('suggest', function(suggestResult) {
			inputElementSuggestion.value = self.parseMapySuggestedAddress(suggestResult);
			
			let geocoder = new SMap.Geocoder(targetAddress, function(geocoderResult) {
				let addressResults = geocoderResult.getResults();
				
				if (addressResults[0]['results'][0] !== undefined) {
					let addressData = addressResults[0]['results'][0];
					
					let startCoords = SMap.Coords.fromWGS84(suggestResult.data.longitude, suggestResult.data.latitude);
					let endCoords = SMap.Coords.fromWGS84(addressData.coords.x, addressData.coords.y);
					
					let smapUrl1 = new SMap.URL.Route().addStart(startCoords, {
						poi: [suggestResult.data.source, suggestResult.data.id]
					}).addDestination(endCoords, {
						poi: [addressData.source, addressData.id]
					});
					
					resultUrl = smapUrl1.toString();
					button.disabled = false;
					
					infoText.classList.add('hidden');
				} else {
					button.disabled = true;
					infoText.classList.add('hidden');					
				}
			});
		});
		
		return el;
	}

	protected destroyComponent(component): void {
		// TODO
	}
	
}
