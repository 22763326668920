import NetteRule from "../../nette/NetteRule";
import FormControlRuleCollection from "./FormControlRuleCollection";
import FormContext from "../../FormContext";
import FormControl from "./FormControl";
import FormValidator from "../../FormValidator";
import FormControlResult from "./FormControlResult";

declare global {
	interface Window {
		formValidator: FormValidator,
	}
}

export default class FormControlRule {
	public readonly target: string;
	public readonly negate: boolean;
	public readonly operation: string;
	public readonly conditional: boolean;
	public readonly argument: string | boolean | number;
	public readonly toggles: Map<string, boolean>;
	public readonly rules: FormControlRuleCollection;
	public readonly message: string;

	public constructor(rule: NetteRule) {
		this.target = rule.control ?? null;

		const matches = rule.op.match(/(~)?([^?]+)/);
		this.negate = matches[1] !== undefined;
		this.operation = matches[2];
		this.conditional = !!rule.rules;
		this.argument = rule.arg;
		this.message = rule.msg ?? null;

		if (rule.toggle) {
			this.toggles = new Map<string, boolean>();
			for (let selector in rule.toggle) {
				const show = rule.toggle[selector];
				if (/^\w[\w.:-]*$/.test(selector)) { // id
					selector = '#' + selector;
				}
				this.toggles.set(selector, show);
			}
		} else {
			this.toggles = null;
		}

		if (this.conditional) {
			this.rules = new FormControlRuleCollection(rule.rules);
		} else {
			this.rules = null;
		}
	}

	public getTargets(): Set<string> {
		const result = this.rules !== null ? this.rules.getTargets() : new Set<string>();
		if (this.target !== null) {
			result.add(this.target)
		}
		return result;
	}

	public getToggles(context: FormContext, control: FormControl, prevSuccess: boolean): Map<string, boolean> {
		let target = this.target !== null ? context.controls.get(this.target) : control;
		if (target === null) {
			console.error(`FormControl ${this.target} not found!`);
		}
		let success = this.validateRule(context, target, this.operation, this.argument);
		if (success === null) {
			return this.rules !== null ? this.rules.getToggles(context, control, prevSuccess) : new Map<string, boolean>();
		}

		if (this.negate) {
			success = !success;
		}
		let result = this.rules !== null ? this.rules.getToggles(context, control, prevSuccess && success) : new Map<string, boolean>();
		if (this.toggles === null) {
			return result;
		}

		this.toggles.forEach((show: boolean, selector: string) => {
			success = prevSuccess && success;
			result.set(selector, show ? success : !success)
		});

		return result;
	}

	public isEmptyOptional(context: FormContext, control: FormControl): boolean {
		if (this.target !== null) {
			control = context.controls.get(this.target);
		}
		return this.operation === 'optional' && !this.validateRule(context, control, ':filled', null);
	}

	public getStatus(context: FormContext, control: FormControl, emptyOptional: boolean): FormControlResult {
		let required = this.operation === ':filled' && !this.conditional;
		let target = this.target !== null ? context.controls.get(this.target) : control;
		let result = this.validateRule(context, target, this.operation, this.argument);
		if (result === null) {
			return [null, null, required];
		}
		if (this.negate) {
			result = !result;
		}


		if (this.conditional && result) {
			let [valid, errorMessage, subRequired] = this.rules.getStatus(context, control, emptyOptional);
			required = required || subRequired;
			if (valid === false) {
				return [false, errorMessage, required];
			}
		} else if (!this.conditional && !result) {
			if (control.isDisabled()) {
				return [null, null, false];
			}
			return [false, this.message, required];
		}

		return [true, null, required];
	}

	private validateRule(context: FormContext, control: FormControl, op: string, arg, value?: any): boolean {
		value = value === undefined ? {value: control.getValue()} : value;

		if (op.charAt(0) === ':') {
			op = op.substr(1);
		}
		op = op.replace('::', '_');
		op = op.replace(/\\/g, '');

		let arr = Array.isArray(arg) ? arg.slice(0) : [arg];
		for (let i = 0, len = arr.length; i < len; i++) {
			if (arr[i] && arr[i].control) {
				arr[i] = context.controls.get(arr[i].control).getValue();
			}
		}

		let validator = window.formValidator.getRule(op);
		if (validator === null) {
			console.error("Form validation method '" + op + "' not implemented!");
			return null;
		}

		return validator(control.getFirstInput(), Array.isArray(arg) ? arr : arr[0], value.value);
	}

}
