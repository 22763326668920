import FormEventTarget from "./FormEventTarget";
import FormEventMap from "./FormEventMap";

export default abstract class AbstractEventTarget implements FormEventTarget {
	private delegate: DocumentFragment;

	protected constructor() {
		this.delegate = document.createDocumentFragment();
	}

	public addEventListener<K extends keyof FormEventMap>(type: K, listener: (ev: FormEventMap[K]) => void, options?: boolean | AddEventListenerOptions): void {
		this.delegate.addEventListener(type, listener, options);
	}

	public dispatchEvent(event: Event): boolean {
		return this.delegate.dispatchEvent(event);
	}

	public removeEventListener(type: string, callback: EventListenerOrEventListenerObject | null, options?: EventListenerOptions | boolean): void {
		this.delegate.removeEventListener(type, callback, options);
	}

}
