import NetteValidators from "./nette/NetteValidators";

export default class FormValidator {
	private readonly methods = new Map<string, (elem: HTMLInputElement, arg, val) => boolean>();

	public setRule(op: string, method: (elem: HTMLInputElement, arg, val) => boolean) {
		this.methods.set(op, method);
	}

	public getRule(op: string): (elem: HTMLInputElement, arg, val) => boolean {
		return this.methods.get(op) ?? NetteValidators[op + 'Validate'] ?? null;
	}

}
