import FormControl from "../components/control/FormControl";
import FormContext from "../FormContext";

export default class FormDependencies {
	private readonly context: FormContext;
	private readonly ascendants: Map<string, Set<string>>;
	private readonly descendants: Map<string, Set<string>>;

	public constructor(context: FormContext) {
		this.context = context;
		this.ascendants = new Map<string, Set<string>>();
		this.descendants = new Map<string, Set<string>>();
	}

	public add(control: FormControl): void {
		if (control.rules === null) {
			return;
		}

		let targets = control.rules.getTargets();
		if (targets.has(control.name)) {
			targets.delete(control.name);
		}

		let ascendants = this.ascendants.get(control.name);
		if (ascendants === undefined) {
			this.ascendants.set(control.name, ascendants = new Set<string>());
		}
		targets.forEach((target: string) => {
			ascendants.add(target);

			let descendants = this.descendants.get(target);
			if (descendants === undefined) {
				this.descendants.set(target, descendants = new Set<string>());
			}
			descendants.add(control.name);
		});
	}

	public delete(control: FormControl): void {
		if (control.rules === null) {
			return;
		}

		let targets = control.rules.getTargets();
		if (targets.has(control.name)) {
			targets.delete(control.name);
		}

		this.ascendants.get(control.name).forEach((target: string) => {
			this.descendants.get(target).delete(control.name);
		})
		this.ascendants.delete(control.name);
	}

	public invalidate(name: string): void {
		let descendants = this.descendants.get(name);
		if (descendants === undefined) {
			return;
		}

		descendants.forEach((name: string) => {
			const control = this.context.controls.get(name);
			if (control !== null) {
				control.invalidate();
			}
			const group = this.context.groups.get(name);
			if (group !== null) {
				group.invalidate();
			}
		})
	}

}
