import {Extension} from "naja/dist/Naja";
import {Naja} from "naja";
import {AfterUpdateEvent} from "naja/dist/core/SnippetHandler";

export default class ScrollUpExtension implements Extension {
	private button: Element;

	public initialize(naja: Naja): void {
		this.processScroll = this.processScroll.bind(this);

		window.addEventListener('scroll', this.processScroll);

		this.initModule(document.querySelector('body'));

		naja.snippetHandler.addEventListener('afterUpdate', (event: AfterUpdateEvent) => {
			this.initModule(event.detail.snippet);
		});
	}

	private initModule(snippet: Element) {
		snippet.querySelectorAll('.init-scroll-up').forEach(el => {
			this.button = el;
		});
	}

	private processScroll(): void {
		if (window.scrollY >= 200) {
			this.button.classList.add('scroll-up-visible');
		} else {
			this.button.classList.remove('scroll-up-visible');
		}
	}

}
