import {Naja} from "naja";
import {Extension} from "naja/dist/Naja";
import {AfterUpdateEvent} from "naja/dist/core/SnippetHandler";

export default class ForwardClickExtension implements Extension {
	initialize(naja: Naja): void {
		this.initModule(document.querySelector('body'));

		naja.snippetHandler.addEventListener('afterUpdate', (event: AfterUpdateEvent) => {
			this.initModule(event.detail.snippet);
		});
	}

	private initModule(snippet: Element) {
		snippet.querySelectorAll('[data-forward-click]').forEach(el => {
			let selector = el.getAttribute("data-forward-click");
			el.addEventListener('click', ev => {
				ev.preventDefault();
				let target = <HTMLElement>document.querySelector(selector);
				if (target !== null) {
					target.click();
				}
			});
		});
	}

}
