import FormControl from "../control/FormControl";

export default class FormGroupStatus {
	private readonly controls: Set<FormControl>;

	private required: boolean = null;
	private requiredDirty: boolean = true;
	private error: boolean = null;
	private valid: boolean = null;
	private validDirty: boolean = true;

	public constructor(controls: Set<FormControl>) {
		this.controls = controls;
	}

	public invalidate(): void {
		this.requiredDirty = true;
		this.validDirty = true;
	}

	public isRequired(): boolean {
		if (this.requiredDirty) {
			this.updateRequired();
		}
		return this.required;
	}

	public isError(): boolean {
		while (this.validDirty) {
			this.updateValid();
		}
		return this.error;
	}

	public isValid(): boolean {
		while (this.validDirty) {
			this.updateValid();
		}
		return this.valid;
	}

	private updateRequired(): void {
		this.required = false;
		this.controls.forEach((control: FormControl) => {
			this.required = this.required || control.isRequired();
		});
		this.requiredDirty = false;
	}

	private updateValid(): void {
		this.error = false;
		this.valid = true;
		this.required = false;

		this.controls.forEach((control: FormControl) => {
			let controlIsValid = control.isValid();
			this.valid = this.valid && controlIsValid
			this.error = this.error || !controlIsValid;
			this.required = this.required || control.isRequired();
		});

		this.requiredDirty = false;
		this.validDirty = false;
	}
}
