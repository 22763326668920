import AbstractComponentExtension from "../AbstractComponentExtension";
// @ts-ignore
import {Datepicker, DateRangePicker} from "vanillajs-datepicker";
import FormValidator from "../../forms/FormValidator";
import {Naja} from "naja";
import * as moment from "moment";

// @ts-ignore
import cs from "vanillajs-datepicker/locales/cs"
// @ts-ignore
import sk from "vanillajs-datepicker/locales/sk"
// @ts-ignore
import de from "vanillajs-datepicker/locales/de"
// @ts-ignore
import ro from "vanillajs-datepicker/locales/ro"
// @ts-ignore
import hu from "vanillajs-datepicker/locales/hu"
Object.assign(Datepicker.locales, cs, sk, de, ro, hu);

declare global {
	interface Window {
		formValidator: FormValidator,
	}
}

export default class DatePickerComponentExtension extends AbstractComponentExtension<Datepicker> {

	private readonly _formatDatepicker = 'dd.mm.yyyy';
	private readonly _formatMoment = 'DD.MM.YYYY';

	public initialize(naja: Naja) {
		super.initialize(naja);

		this.validatorMethod = this.validatorMethod.bind(this);
		this.validateMinDate = this.validateMinDate.bind(this);
		this.validateMaxDate = this.validateMaxDate.bind(this);

		window.formValidator.setRule('AppFormsControlsDatePickerDatePickerInput_validator', this.validatorMethod);
		window.formValidator.setRule('AppFormsControlsDatePickerDatePickerInput_validateMinDate', this.validateMinDate);
		window.formValidator.setRule('AppFormsControlsDatePickerDatePickerInput_validateMaxDate', this.validateMaxDate);
	}

	protected getSelector(): string {
		return '.init-datepicker';
	}

	protected createComponent(el: Element): Datepicker {
		let options = {
			language: document.documentElement.lang,
			format: this._formatDatepicker,
			weekStart: 1,

			autohide: true,
			buttonClass: 'btn',

			todayBtn: true,
			clearBtn: !el.classList.contains('is-required'),

			minDate: null,
			maxDate: null,
		};

		const minDate = el.getAttribute('data-datepicker-min');
		if (minDate !== null) {
			options.minDate = new Date(minDate);
		}

		const maxDate = el.getAttribute('data-datepicker-max');
		if (maxDate !== null) {
			options.maxDate = new Date(maxDate);
		}

		const datepicker = new Datepicker(el, options);

		el.setAttribute('autocomplete', 'off');
		el.setAttribute('role', 'presentation');

		el.addEventListener('changeDate', (event) => {
			el.dispatchEvent(new Event('change'));
		});

		return datepicker;
	}

	protected destroyComponent(component: Datepicker): void {
		component.destroy();
	}

	private validatorMethod(elem, arg, val): boolean {
		try {
			return typeof arg === 'string' ? (new RegExp('^(?:' + arg + ')$')).test(val) : null;
		} catch (e) {
			return null;
		}
	}

	private validateMinDate(elem, arg, val): boolean {
		let valMoment = moment(val, this._formatMoment);
		let argMoment = moment(arg.date.split(' ')[0], 'YYYY-MM-DD');
		if (!valMoment.isValid() || !argMoment.isValid()) {
			return false;
		}

		return valMoment.toDate() >= argMoment.toDate();
	}

	private validateMaxDate(elem, arg, val): boolean {
		let valMoment = moment(val, this._formatMoment);
		let argMoment = moment(arg.date.split(' ')[0], 'YYYY-MM-DD');
		if (!valMoment.isValid() || !argMoment.isValid()) {
			return false;
		}

		return valMoment.toDate() <= argMoment.toDate();
	}

}
