import AbstractComponentExtension from "../AbstractComponentExtension";
import Dropdown from "bootstrap/js/src/dropdown";

export default class BootstrapDropdownComponentExtension extends AbstractComponentExtension<Dropdown> {

	protected getSelector(): string {
		return '[data-bs-toggle="dropdown"]';
	}

	protected createComponent(el: Element) {
		return Dropdown.getOrCreateInstance(el);
	}

	protected destroyComponent(component): void {
		component.dispose();
	}

}
