export default class NetteValidators {

	public static filledValidate(elem: HTMLInputElement, arg, val): boolean {
		if (elem.type === 'number' && elem.validity.badInput) {
			return true;
		}
		return val !== '' && val !== false && val !== null
			&& (!Array.isArray(val) || !!val.length)
			&& (!window.FileList || !(val instanceof FileList) || val.length > 0);
	}

	public static blankValidate(elem: HTMLInputElement, arg, val): boolean {
		return !NetteValidators.filledValidate(elem, arg, val);
	}

	public static validValidate(elem: HTMLInputElement, arg, val): boolean {
		throw new Error("NetteValidator rule 'valid' is not supported!");
	}

	public static equalValidate(elem: HTMLInputElement, arg, val): boolean {
		if (arg === undefined) {
			return null;
		}

		const valArray = Array.isArray(val) ? val : [val];
		const argArray = Array.isArray(arg) ? arg : [arg];

		loop:
			for (let i1 = 0, len1 = valArray.length; i1 < len1; i1++) {
				let valString = NetteValidators.toString(valArray[i1]);
				for (let i2 = 0, len2 = argArray.length; i2 < len2; i2++) {
					if (valString === NetteValidators.toString(argArray[i2])) {
						continue loop;
					}
				}
				return false;
			}

		return valArray.length > 0;
	}

	public static notEqualValidate(elem: HTMLInputElement, arg, val): boolean {
		return arg === undefined ? null : !NetteValidators.equalValidate(elem, arg, val);
	}

	public static minLengthValidate(elem: HTMLInputElement, arg, val): boolean {
		if (elem.type === 'number') {
			if (elem.validity.tooShort) {
				return false;
			} else if (elem.validity.badInput) {
				return null;
			}
		}
		return val.length >= arg;
	}

	public static maxLengthValidate(elem: HTMLInputElement, arg, val): boolean {
		if (elem.type === 'number') {
			if (elem.validity.tooLong) {
				return false;
			} else if (elem.validity.badInput) {
				return null;
			}
		}
		return val.length <= arg;
	}

	public static lengthValidate(elem: HTMLInputElement, arg, val): boolean {
		if (elem.type === 'number') {
			if (elem.validity.tooShort || elem.validity.tooLong) {
				return false;
			} else if (elem.validity.badInput) {
				return null;
			}
		}
		arg = Array.isArray(arg) ? arg : [arg, arg];
		return (arg[0] === null || val.length >= arg[0]) && (arg[1] === null || val.length <= arg[1]);
	}

	public static emailValidate(elem: HTMLInputElement, arg, val): boolean {
		return (/^("([ !#-[\]-~]|\\[ -~])+"|[-a-z0-9!#$%&'*+/=?^_`{|}~]+(\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*)@([0-9a-z\u00C0-\u02FF\u0370-\u1EFF]([-0-9a-z\u00C0-\u02FF\u0370-\u1EFF]{0,61}[0-9a-z\u00C0-\u02FF\u0370-\u1EFF])?\.)+[a-z\u00C0-\u02FF\u0370-\u1EFF]([-0-9a-z\u00C0-\u02FF\u0370-\u1EFF]{0,17}[a-z\u00C0-\u02FF\u0370-\u1EFF])?$/i).test(val);
	}

	public static urlValidate(elem: HTMLInputElement, arg, val): boolean {
		if (!(/^[a-z\d+.-]+:/).test(val)) {
			val = 'https://' + val;
		}
		if ((/^https?:\/\/((([-_0-9a-z\u00C0-\u02FF\u0370-\u1EFF]+\.)*[0-9a-z\u00C0-\u02FF\u0370-\u1EFF]([-0-9a-z\u00C0-\u02FF\u0370-\u1EFF]{0,61}[0-9a-z\u00C0-\u02FF\u0370-\u1EFF])?\.)?[a-z\u00C0-\u02FF\u0370-\u1EFF]([-0-9a-z\u00C0-\u02FF\u0370-\u1EFF]{0,17}[a-z\u00C0-\u02FF\u0370-\u1EFF])?|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\[[0-9a-f:]{3,39}\])(:\d{1,5})?(\/\S*)?$/i).test(val)) {
			return true;
		}
		return false;
	}

	public static regexpValidate(elem: HTMLInputElement, arg, val): boolean {
		let parts = typeof arg === 'string' ? arg.match(/^\/(.*)\/([imu]*)$/) : false;
		try {
			return parts && (new RegExp(parts[1], parts[2].replace('u', ''))).test(val);
		} catch (e) {}
	}

	public static patternValidate(elem: HTMLInputElement, arg, val, value, caseInsensitive: boolean): boolean {
		if (typeof arg !== 'string') {
			return null;
		}

		try {
			let regExp;
			try {
				regExp = new RegExp('^(?:' + arg + ')$', caseInsensitive ? 'ui' : 'u');
			} catch (e) {
				regExp = new RegExp('^(?:' + arg + ')$', caseInsensitive ? 'i' : '');
			}

			if (window.FileList && val instanceof FileList) {
				for (let i = 0; i < val.length; i++) {
					if (!regExp.test(val[i].name)) {
						return false;
					}
				}

				return true;
			}

			return regExp.test(val);
		} catch (e) {} // eslint-disable-line no-empty
	}

	public static patternCaseInsensitiveValidate(elem: HTMLInputElement, arg, val): boolean {
		return NetteValidators.patternValidate(elem, arg, val, null, true);
	}

	public static numericValidate(elem: HTMLInputElement, arg, val): boolean {
		if (elem.type === 'number' && elem.validity.badInput) {
			return false;
		}
		return (/^[0-9]+$/).test(val);
	}

	public static integerValidate(elem: HTMLInputElement, arg, val): boolean {
		if (elem.type === 'number' && elem.validity.badInput) {
			return false;
		}
		return (/^-?[0-9]+$/).test(val);
	}

	public static floatValidate(elem: HTMLInputElement, arg, val, value): boolean {
		if (elem.type === 'number' && elem.validity.badInput) {
			return false;
		}
		val = val.replace(/ +/g, '').replace(/,/g, '.');
		if ((/^-?[0-9]*\.?[0-9]+$/).test(val)) {
			if (value) {
				value.value = val;
			}
			return true;
		}
		return false;
	}

	public static minValidate(elem: HTMLInputElement, arg, val): boolean {
		if (elem.type === 'number') {
			if (elem.validity.rangeUnderflow) {
				return false;
			} else if (elem.validity.badInput) {
				return null;
			}
		}
		return arg === null || parseFloat(val) >= arg;
	}

	public static maxValidate(elem: HTMLInputElement, arg, val): boolean {
		if (elem.type === 'number') {
			if (elem.validity.rangeOverflow) {
				return false;
			} else if (elem.validity.badInput) {
				return null;
			}
		}
		return arg === null || parseFloat(val) <= arg;
	}

	public static rangeValidate(elem: HTMLInputElement, arg, val): boolean {
		if (elem.type === 'number') {
			if (elem.validity.rangeUnderflow || elem.validity.rangeOverflow) {
				return false;
			} else if (elem.validity.badInput) {
				return null;
			}
		}
		return Array.isArray(arg) ?
			((arg[0] === null || parseFloat(val) >= arg[0]) && (arg[1] === null || parseFloat(val) <= arg[1])) : null;
	}

	public static submittedValidate(elem: HTMLInputElement, arg, val): boolean {
		return elem.form['nette-submittedBy'] === elem;
	}

	public static fileSizeValidate(elem: HTMLInputElement, arg, val): boolean {
		if (window.FileList) {
			for (let i = 0; i < val.length; i++) {
				if (val[i].size > arg) {
					return false;
				}
			}
		}
		return true;
	}

	public static mimeTypeValidate(elem: HTMLInputElement, arg, val): boolean {
		arg = Array.isArray(arg) ? arg : [arg];
		let reArr = [];
		for (let i = 0, len = arg.length; i < len; i++) {
			reArr.push('^' + arg[i].replace(/([^\w])/g, '\\$1').replace('\\*', '.*') + '$');
		}
		let re = new RegExp(reArr.join('|'));

		if (window.FileList && val instanceof FileList) {
			for (let i = 0; i < val.length; i++) {
				if (val[i].type && !re.test(val[i].type)) {
					return false;
				} else if (elem.validity.badInput) {
					return null;
				}
			}
		}
		return true;
	}

	public static imageValidate(elem: HTMLInputElement, arg, val): boolean {
		return NetteValidators.mimeTypeValidate(elem, ['image/gif', 'image/png', 'image/jpeg', 'image/webp'], val);
	}

	public static staticValidate(elem: HTMLInputElement, arg, val): boolean {
		return arg;
	}

	private static toString(val): string {
		if (typeof val === 'number' || typeof val === 'string') {
			return '' + val;
		} else {
			return val === true ? '1' : '';
		}
	}

}
