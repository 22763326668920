export default class GalleryBanner {
	private readonly DURATION_INIT: number = 1000;
	private readonly DURATION_SHOW: number = 2000;
	private readonly DURATION_HIDE: number = 1000;

	private readonly container: HTMLElement;
	private readonly images: NodeListOf<Element>;
	private readonly items: NodeListOf<Element>;
	private currentIndex: number;
	private currentItem: Element;
	private timeout: NodeJS.Timeout;

	public constructor(el: HTMLElement) {
		this.container = el;
		this.images = el.querySelectorAll('[data-type="gallery-banner-image"]');
		this.items = el.querySelectorAll('[data-type="gallery-banner-item"]');
		this.currentIndex = 0;
		this.currentItem = null;

		this.hideCurrent = this.hideCurrent.bind(this);
		this.showNext = this.showNext.bind(this);
		this.hoverItem = this.hoverItem.bind(this);
		this.hoverItemLeave = this.hoverItemLeave.bind(this);

		if (this.items.length > 1) {
			this.timeout = setTimeout(this.showNext, this.DURATION_INIT);
			this.items.forEach((item: Element) => item.addEventListener('mouseover', this.hoverItem));
			this.items.forEach((item: Element) => item.addEventListener('mouseleave', this.hoverItemLeave));
		}
	}

	public destroy(): void {
		if (this.timeout !== null) {
			clearTimeout(this.timeout);
		}

		this.items.forEach((item: Element) => item.removeEventListener('mouseover', this.hoverItem));
		this.items.forEach((item: Element) => item.removeEventListener('mouseleave', this.hoverItemLeave));
	}

	private hideCurrent(): void {
		if (this.currentItem !== null) {
			this.currentItem.classList.remove('active');
			this.currentItem = null;
		}

		this.timeout = setTimeout(this.showNext, this.DURATION_HIDE);
	}

	private showNext(): void {
		this.currentItem = this.items.item(this.currentIndex);
		this.currentItem.classList.add('active')
		this.currentIndex = (this.currentIndex + 1) % this.items.length;

		this.timeout = setTimeout(this.hideCurrent, this.DURATION_SHOW);
	}

	private hoverItem(): void {
		if (this.timeout !== null) {
			clearTimeout(this.timeout);
		}
	}

	private hoverItemLeave(): void {
		this.timeout = setTimeout(this.hideCurrent, this.DURATION_HIDE);
	}

}
