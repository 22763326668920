import naja from 'naja';

import AlertsExtension from "./extensions/AlertsExtension";
import BootstrapAlertComponentExtension from "./components/bootstrap/BootstrapAlertComponentExtension";
import BootstrapDropdownComponentExtension from "./components/bootstrap/BootstrapDropdownComponentExtension";
import BootstrapTooltipComponentExtension from "./components/bootstrap/BootstrapTooltipComponentExtension";
import BootstrapPopoverComponentExtension from "./components/bootstrap/BootstrapPopoverComponentExtension";
import BootstrapCarouselComponentExtension from "./components/bootstrap/BootstrapCarouselComponentExtension";
import BootstrapCollapseComponentExtension from "./components/bootstrap/BootstrapCollapseComponentExtension";
import BootstrapTabComponentExtension from "./components/bootstrap/BootstrapTabComponentExtension";
import CookieConsentExtension from "./extensions/CookieConsentExtension";
import FormValidationExtension from "./extensions/FormValidationExtension";
import FormRefreshExtension from "./extensions/FormRefreshExtension";
import FormAutoSubmitExtension from "./extensions/FormAutoSubmitExtension";
import ForwardClickExtension from "./extensions/ForwardClickExtension";
import ScrollUpExtension from "./extensions/ScrollUpExtension";
import ToggleClassExtension from "./extensions/ToggleClassExtension";
import TransitionExtension from "./extensions/TransitionExtension";
import CompanyContactsStickExtension from "./extensions/CompanyContactsStickExtension";
import TomSelectComponentExtension from "./components/select/TomSelectComponentExtension";
import DatePickerComponentExtension from "./components/datepicker/DatePickerComponentExtension";
import SwiperSliderComponentExtension from "./components/slider/SwiperSliderComponentExtension";
import GalleryBannerComponentExtension from "./components/banner/GalleryBannerComponentExtension";
import SimpleLightboxComponentExtension from "./components/simplelightbox/SimpleLightboxComponentExtension";
import CompanyMapComponentExtension from "./components/company-map/CompanyMapComponentExtension";
import CompanyMapItineraryClientComponentExtension from "./components/company-map/CompanyMapItineraryClientComponentExtension";
import CompanyMapItineraryNoClientComponentExtension from "./components/company-map/CompanyMapItineraryNoClientComponentExtension";
import ListingComponentExtension from "./components/listing/ListingComponentExtension";

// extensions
naja.registerExtension(new AlertsExtension());
naja.registerExtension(new CookieConsentExtension())
naja.registerExtension(new TransitionExtension('body > .transition-auto', 300));
naja.registerExtension(new ForwardClickExtension());
naja.registerExtension(new FormValidationExtension());
naja.registerExtension(new FormRefreshExtension());
naja.registerExtension(new FormAutoSubmitExtension());
naja.registerExtension(new ToggleClassExtension());
naja.registerExtension(new ScrollUpExtension());
naja.registerExtension(new CompanyContactsStickExtension());

// components
naja.registerExtension(new BootstrapAlertComponentExtension());
naja.registerExtension(new BootstrapDropdownComponentExtension());
naja.registerExtension(new BootstrapTooltipComponentExtension());
naja.registerExtension(new BootstrapPopoverComponentExtension());
naja.registerExtension(new BootstrapCarouselComponentExtension());
naja.registerExtension(new BootstrapCollapseComponentExtension());
naja.registerExtension(new BootstrapTabComponentExtension());
naja.registerExtension(new TomSelectComponentExtension());
naja.registerExtension(new DatePickerComponentExtension());
naja.registerExtension(new SwiperSliderComponentExtension());
naja.registerExtension(new GalleryBannerComponentExtension());
naja.registerExtension(new SimpleLightboxComponentExtension());
naja.registerExtension(new CompanyMapComponentExtension());
naja.registerExtension(new CompanyMapItineraryClientComponentExtension());
naja.registerExtension(new CompanyMapItineraryNoClientComponentExtension());
naja.registerExtension(new ListingComponentExtension());

naja.initialize({
	snippetCache: false,
});
