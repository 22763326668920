import AbstractComponentExtension from "../AbstractComponentExtension";
import SimpleLightbox from "simplelightbox";

export default class SimpleLightboxComponentExtension extends AbstractComponentExtension<SimpleLightbox> {
    protected getSelector(): string {
        return ".init-simplelightbox";
    }

    protected createComponent(el: HTMLElement): SimpleLightbox {
		let config = {
			sourceAttr: 'data-image'
		};
		
        return new SimpleLightbox(el.querySelectorAll('[data-image]'), config);
    }

    protected destroyComponent(component: SimpleLightbox): void {
        component.destroy();
    }

}
