import NetteRule from "../../nette/NetteRule";
import FormControlRule from "./FormControlRule";
import FormControl from "./FormControl";
import FormContext from "../../FormContext";
import FormControlResult from "./FormControlResult";

export default class FormControlRuleCollection {
	private readonly rules: FormControlRule[];

	public constructor(rules: NetteRule[]) {
		this.rules = [];
		for (const rule of rules) {
			this.rules.push(new FormControlRule(rule));
		}
	}

	public getTargets(): Set<string> {
		const result = new Set<string>();
		for (const rule of this.rules) {
			rule.getTargets()
				.forEach((target) => result.add(target));
		}
		return result;
	}

	public getToggles(context: FormContext, control: FormControl, prevSuccess: boolean = true): Map<string, boolean> {
		const result = new Map<string, boolean>();
		for (const rule of this.rules) {
			rule.getToggles(context, control, prevSuccess)
				.forEach((show: boolean, selector: string) => {
					if (show !== null) {
						result.set(selector, show)
					}
				})
		}
		return result;
	}

	public getStatus(context: FormContext, control: FormControl, emptyOptional?: boolean): FormControlResult {
		if (emptyOptional === undefined) {
			emptyOptional = false;
		}
		let required = false;
		for (const rule of this.rules) {
			if (rule.isEmptyOptional(context, control)) {
				emptyOptional = true;
				continue;
			}
			if (emptyOptional && !rule.conditional && rule.operation !== ':filled') {
				continue;
			}
			let [valid, errorMessage, subRequired] = rule.getStatus(context, control, emptyOptional);
			required = required || subRequired;
			if (valid === false) {
				return [false, errorMessage, required];
			}
		}
		return [true, null, required];
	}

	public static from(inputs: HTMLInputElement[]): FormControlRuleCollection {
		let rulesFound = [];
		let rulesClosed = new Map<string, boolean>();
		for (const input of inputs) {
			let rulesAttribute = input.getAttribute('data-nette-rules');
			if (rulesAttribute === null) {
				continue;
			}
			if (rulesClosed.has(rulesAttribute)) {
				continue;
			}

			rulesClosed.set(rulesAttribute, true);
			rulesFound.push(...JSON.parse(rulesAttribute))
		}
		if (rulesFound.length === 0) {
			return null;
		}

		return new FormControlRuleCollection(rulesFound);
	}

}
