import {Naja} from "naja";
import {Extension} from "naja/dist/Naja";
import {AfterUpdateEvent} from "naja/dist/core/SnippetHandler";

export default class ToggleClassExtension implements Extension {
	initialize(naja: Naja): void {
		this.initModule(document.querySelector('body'));

		naja.snippetHandler.addEventListener('afterUpdate', (event: AfterUpdateEvent) => {
			this.initModule(event.detail.snippet);
		});
	}

	private initModule(snippet: Element) {
		snippet.querySelectorAll('[data-toggle-class]').forEach(el => {
			el.getAttribute('data-toggle-class').split(';').forEach(item => {
				let [selector, className] = item.split(',');

				el.addEventListener('click', ev => {
					ev.preventDefault();

					document.querySelectorAll(`.${selector}`).forEach((el) => {
						el.classList.toggle(className);
					});
				});
			});
		});
	}

}
