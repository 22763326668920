import AbstractComponentExtension from "../AbstractComponentExtension";
import Popover from 'bootstrap/js/src/popover';

export default class BootstrapPopoverComponentExtension extends AbstractComponentExtension<Popover> {

	protected getSelector(): string {
		return '[data-bs-toggle="popover"]';
	}

	protected createComponent(el: Element) {
		el.addEventListener('click', (ev) => ev.preventDefault());

		let options = {
			html: false,
			content: null,
			customClass: '',
		};

		let contentSelector = el.getAttribute("data-bs-content-html");
		if (contentSelector !== null) {
			options.html = true;
			options.content = function () {
				return document.querySelector(contentSelector).innerHTML;
			};
		}
		let customClass = el.getAttribute("data-bs-custom-class");
		if (customClass !== null) {
			options.customClass = customClass;
		}

		return Popover.getOrCreateInstance(el, options);
	}

	protected destroyComponent(component): void {
		component.dispose();
	}

}
