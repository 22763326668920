import AbstractComponentExtension from "../AbstractComponentExtension";

// objekt SMap se natáhne z externího skriptu v šabloně
declare var SMap: any;

export default abstract class AbstractCompanyMapItineraryComponentExtension<T> extends AbstractComponentExtension<HTMLElement> {
	
	protected getAvailableCategories(): string
	{
		return 'address_cz,address_sk,area_cz,area_osm,country_cz,country_sk,country_osm,district_cz,district_sk,district_osm,municipality_cz, municipality_sk,municipality_osm,quarter_cz,quarter_sk,region_cz,region_sk,region_osm,street_cz,street_sk,street_osm';
	}
	
	protected parseMapySuggestedAddress(searchData: Object): string
	{
		let secondRow = searchData['data']['secondRow'];
		let parts = secondRow.split(',');
		
		let excludeWords = new Array('adresa', 'osada', 'místní název', 'mezník', 'hraniční kámen', 'ulice', 'čtvrť', 'zastávka', 'sídliště', 'město', 'náměstí', 'kraj', 'jiná zajímavost', 'obec', 'část obce', 'vesnice', 'hora', 'jeskyně', 'výhled', 'zřícenina', 'zámek', 'skalní útvar', 'klášter', 'kostel', 'rašeliniště', 'statutární město', 'budova', 'vodní plocha', 'vojvodství', 'pohoří');
		
		let newParts = new Array();

		parts.forEach(function(element, index) {
			let value = element.trim().toLowerCase();
			
			if (!excludeWords.includes(value)) {
				newParts.push(element.trim());
			}
		});
		
		let newPartsString = newParts.join(', ');
		let resultString = searchData['data']['phrase'] + ', ' + newPartsString;
		
		return resultString;
	}
	
	protected createSuggest(inputElementSuggestion: HTMLInputElement): any
	{
		let suggest = new SMap.Suggest(inputElementSuggestion, {
			provider: new SMap.SuggestProvider({
				updateParams: params => {
					params.lang = 'cs';
				}
			})
		});
		
		suggest.urlParams({
			'category': this.getAvailableCategories()
		});
		
		return suggest;
	}
	
}
