import FormOptions from "./FormOptions";
import AbstractEventTarget from "./events/AbstractEventTarget";
import FormEvent from "./events/FormEvent";
import FormDependencies from "./context/FormDependencies";
import FormControls from "./context/FormControls";
import FormGroups from "./context/FormGroups";
import FormControl from "./components/control/FormControl";

export default class FormContext extends AbstractEventTarget {
	public readonly options: FormOptions;
	public readonly form: HTMLFormElement;

	public readonly dependencies: FormDependencies;
	public readonly controls: FormControls;
	public readonly groups: FormGroups;

	public readonly showError: boolean;
	public readonly showValid: boolean;

	public constructor(options: FormOptions, form: HTMLFormElement) {
		super();
		form.noValidate = true;

		this.options = options;
		this.form = form;

		this.dependencies = new FormDependencies(this);
		this.controls = new FormControls(this);
		this.groups = new FormGroups(this);

		this.showError = form.matches(options.formEnableShowErrorSelector);
		this.showValid = form.matches(options.formEnableShowValidSelector);

		this.processSnippet(form);
	}

	public dispatchEvent(event: FormEvent): boolean {
		return super.dispatchEvent(event)
			&& this.form.dispatchEvent(new CustomEvent(event.type + 'Form'));
	}

	public processSnippet(snippet: Element) {
		this.controls.processSnippet(snippet);
		this.groups.processSnippet(snippet);
	}

	public destroySnippet(snippet: Element) {
		this.controls.destroySnippet(snippet);
	}

	public isValid(sender: Element): boolean {
		// setValidationScope([]) nevytváří data-nette-validation-scope ale každý setValidationScope(...) vytváří "formnovalidate".
		if (sender.hasAttribute('formnovalidate')) {
			this.dispatchEvent(new CustomEvent('validate', {detail: {sender: this}}));
			return true;
		}

		let scopeRegex: RegExp = null;
		let scopeAttribute = sender.getAttribute('data-nette-validation-scope');
		if (scopeAttribute !== null) {
			let scopeArray: string[] = JSON.parse(scopeAttribute);
			if (scopeArray.length) {
				scopeRegex = new RegExp('^(' + scopeArray.join('-|') + '-)');
			}
		}

		let result = true;
		this.controls.forEach((control: FormControl, name: string) => {
			if (scopeRegex !== null && !name.replace(/]\[|\[|]|$/g, '-').match(scopeRegex)) {
				return;
			}
			if (!control.isValid()) {
				result = false;
				console.log('INVALID', control, sender);
			}
		});

		this.dispatchEvent(new CustomEvent('validate', {detail: {sender: this}}));

		return result;
	}
}
