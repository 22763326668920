import AbstractComponentExtension from "../AbstractComponentExtension";
import TomSelect from "tom-select";
import {RecursivePartial, TomInput, TomLoadCallback, TomOption, TomSettings} from "tom-select/dist/types/types";

declare global {
	interface Window {
		tomSelectInputs: WeakMap<Element, TomInput>,
	}
}

export default class TomSelectComponentExtension extends AbstractComponentExtension<TomSelect> {

	public constructor() {
		super();
		window.tomSelectInputs = new WeakMap<Element, TomInput>();
	}

	protected getSelector(): string {
		return 'select';
	}

	protected createComponent(el: TomInput) {
		let settings = <RecursivePartial<TomSettings>>{
			create: false,
			hidePlaceholder: true,
			plugins: ['change_listener'],
			render: {
				option_create: function (data, escape): string {
					return '<div class="create">' + window.appConfig.select.create + ' <strong>' + escape(data.input) + '</strong>&hellip;</div>';
				},
				no_results: function (data, escape): string {
					return '<div class="no-results">' + window.appConfig.select.empty + '</div>';
				},
			}
		};
		if (el.hasAttribute('multiple')) {
			settings.plugins = ['no_backspace_delete', 'remove_button'];

			let sort = el.getAttribute('data-select-sort');
			if (sort !== null) {
				settings.plugins.push('drag_drop');
			}
		}
		if (el.hasAttribute('data-select-allow-create')) {
			settings.create = true;
		}

		let maxOptions = el.getAttribute('data-select-max-options');
		if (maxOptions !== null) {
			settings.maxOptions = parseInt(maxOptions);
		}

		let itemLayout = el.getAttribute('data-select-item-layout');
		if (itemLayout !== null) {
			settings = this.appendLayout(itemLayout, settings);
		}

		let placeholder = el.getAttribute('data-select-placeholder');
		if (placeholder !== null) {
			settings.placeholder = placeholder;
			settings.hidePlaceholder = false;
		}

		let loadUrl = el.getAttribute('data-select-load-url');
		if (loadUrl !== null) {
			settings.load = this.processLoadCallback(el, loadUrl);
		}

		window.tomSelectInputs.set(el, el);

		return new TomSelect(el, settings);
	}

	protected destroyComponent(component): void {
		component.destroy();
	}

	private processLoadCallback(el: Element, loadUrl: string) {
		let queryDataJson = el.getAttribute('data-select-query-data');
		let queryData = {};
		if (queryDataJson !== null) {
			queryData = JSON.parse(queryDataJson);
		}

		return function (query: string, callback: TomLoadCallback) {
			const url = new URL(loadUrl, location.href);

			url.searchParams.append('query', query);
			for (let key in queryData) {
				url.searchParams.append(`queryData[${key}]`, queryData[key]);
			}

			fetch(url.toString())
				.then(response => response.json())
				.then(json => callback(<TomOption[]>json.items, []))
				.catch(reason => {
					console.error(reason);
					callback([], []);
				});
		};
	}

	private appendLayout(layout: string, settings: RecursivePartial<TomSettings>): RecursivePartial<TomSettings> {
		if (layout === 'icon') {
			return this.appendIconLayout(settings);
		}
		if (layout === 'image') {
			return this.appendImageLayout(settings);
		}
		if (layout === 'flag') {
			return this.appendFlagLayout(settings);
		}
		if (layout === 'prefix') {
			return this.appendPrefixLayout(settings);
		}
		if (layout === 'company') {
			return this.appendCompanyLayout(settings);
		}
		return settings;
	}

	private appendIconLayout(settings: RecursivePartial<TomSettings>): RecursivePartial<TomSettings> {
		let renderFunc = function (data, escape): string {
			let icon = data.$option.getAttribute('data-icon');
			if (icon === null) {
				icon = data.value;
			}

			let color = data.$option.getAttribute('data-color');
			if (color === null) {
				color = 'body';
			}

			return '<div>' +
				'<i class="' + escape(icon) + ' text-' + escape(color) + ' me-2"></i>' +
				'<span>' + escape(data.text) + '</span>' +
				'</div>';
		};
		settings.searchField = ['text', 'tags'];
		settings.maxOptions = null;
		settings.render.option = renderFunc;
		settings.render.item = renderFunc;

		return settings;
	}

	private appendImageLayout(settings: RecursivePartial<TomSettings>): RecursivePartial<TomSettings> {
		let renderFunc = function (data, escape): string {
			let image = data.$option.getAttribute('data-image');
			if (image === null) {
				image = data.value;
			}

			return '<div class="d-flex align-items-center">' +
				'<div class="icon-block-1 me-1" style="max-height: 1rem">' +
				'<img src="' + image + '" class="icon-image-1" alt="" style="max-height: 1rem" />' +
				'</div><span>' + escape(data.text) + '</span>' +
				'</div>';
		};
		settings.searchField = ['text'];
		settings.maxOptions = null;
		settings.render.option = renderFunc;
		settings.render.item = renderFunc;

		return settings;
	}

	private appendFlagLayout(settings: RecursivePartial<TomSettings>): RecursivePartial<TomSettings> {
		let renderFunc = function (data, escape): string {
			let flag = data.$option.getAttribute('data-flag-icon');
			if (flag === null) {
				flag = data.value;
			}
			return '<div>' +
				'<i class="flag-icon flag-icon-' + escape(flag.toLowerCase()) + ' me-2"></i>' +
				'<span>' + escape(data.text) + '</span>' +
				'</div>';
		};
		settings.render.option = renderFunc;
		settings.render.item = renderFunc;

		return settings;
	}

	private appendPrefixLayout(settings: RecursivePartial<TomSettings>): RecursivePartial<TomSettings> {
		let renderFunc = function (data, escape): string {
			let prefix = null;
			if (data.hasOwnProperty('$option')) {
				prefix = data.$option.getAttribute('data-prefix') ?? null;
			}
			if (prefix === null) {
				prefix = data.value;
			}
			return '<div class="d-flex align-items-start">' +
				'<span class="font-monospace text-muted small mt-1 me-2">' + escape(prefix) + '</span>' +
				'<span>' + escape(data.text) + '</span>' +
				'</div>';
		};
		settings.searchField = ['text', 'value', 'prefix'];
		settings.render.option = renderFunc;
		settings.render.item = renderFunc;

		return settings;
	}

	private appendCompanyLayout(settings: RecursivePartial<TomSettings>): RecursivePartial<TomSettings> {
		settings.searchField = ['text', 'ico'];
		settings.render.option = function (data, escape): string {
			let ico = null;
			if (data.hasOwnProperty('$option')) {
				ico = data.$option.getAttribute('data-ico') ?? null;
			}
			if (ico === null) {
				ico = data.ico ?? null;
			}
			return '<div class="d-flex w-100 justify-content-between align-items-center">' +
				'<span>' + escape(data.text) + '</span>' +
				(ico !== null ? '<span class="font-monospace text-muted small mt-1 me-2">' + escape(ico) + '</span>' : '') +
				'</div>';
		};
		settings.render.item = function (data, escape): string {
			let ico = null;
			if (data.hasOwnProperty('$option')) {
				ico = data.$option.getAttribute('data-ico') ?? null;
			}
			if (ico === null) {
				ico = data.ico ?? null;
			}
			return '<div class="d-flex align-items-center">' +
				'<span>' + escape(data.text) + '</span>' +
				(ico !== null ? '<span class="font-monospace text-muted small mt-1 mx-2">' + escape(ico) + '</span>' : '') +
				'</div>';
		};

		return settings;
	}

}
