import Swiper from "swiper";
import {Navigation, Pagination} from "swiper/modules";
import {SwiperOptions} from "swiper/types/swiper-options";


export default class SwiperSlider {
	private readonly DURATION_DEFAULT: number = 3000;
	private readonly DURATION_HIDE: number = 1000;

	private readonly container: HTMLElement;
	private readonly swiper: Swiper;
	private readonly slides: NodeListOf<Element>;
	private currentIndex: number;
	private timeout: NodeJS.Timeout;

	public constructor(el: HTMLElement) {
		this.container = el;
		this.slides = el.querySelectorAll('.swiper-slide');
		this.currentIndex = 0;

		this.swiper = new Swiper(el, <SwiperOptions>{
			loop: true,
			rewind: true,
			modules: [Navigation, Pagination],
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
				type: 'bullets'
			}
		});

		this.slideNext = this.slideNext.bind(this);
		this.hoverSlide = this.hoverSlide.bind(this);
		this.hoverSlideLeave = this.hoverSlideLeave.bind(this);

		this.timeout = setTimeout(this.slideNext, this.getSlideDuration())
		this.slides.forEach((item: Element) => item.addEventListener('mouseover', this.hoverSlide));
		this.slides.forEach((item: Element) => item.addEventListener('mouseleave', this.hoverSlideLeave));
	}

	public destroy() {
		if (this.timeout !== null) {
			clearTimeout(this.timeout);
		}

		this.slides.forEach((item: Element) => item.removeEventListener('mouseover', this.hoverSlide));
		this.slides.forEach((item: Element) => item.removeEventListener('mouseleave', this.hoverSlideLeave));

		this.swiper.destroy(true, false);
	}

	private slideNext(): void {
		this.swiper.slideNext();
		this.currentIndex = (this.currentIndex + 1) % this.slides.length;
		this.timeout = setTimeout(this.slideNext, this.getSlideDuration());
	}

	private getSlideDuration(): number {
		let currentSlide = this.slides[this.currentIndex];
		let durationData = currentSlide.getAttribute('data-swiper-duration');
		if (durationData !== null) {
			return parseInt(durationData);
		}

		return this.DURATION_DEFAULT;
	}

	private hoverSlide(): void {
		if (this.timeout !== null) {
			clearTimeout(this.timeout);
		}
	}

	private hoverSlideLeave(): void {
		this.timeout = setTimeout(this.slideNext, this.DURATION_HIDE);
	}

}
