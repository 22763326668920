import FormContext from "../FormContext";
import FormOptions from "../FormOptions";

export default class AbstractFetcher {
	private options: FormOptions;

	protected constructor(context: FormContext) {
		this.options = context.options;
	}

	protected fetchGroupedInputs(snippet: Element): Map<string, HTMLInputElement[]> {
		const result = new Map<string, HTMLInputElement[]>();
		const inputsList = snippet.querySelectorAll(this.options.controlSelector);
		const inputs = <HTMLInputElement[]>Array.from(inputsList).filter((input: Element) => {
			if (input.matches(this.options.controlDisableValidationSelector)) {
				return false;
			}
			if (input instanceof HTMLInputElement) {
				return input.name && input.name !== '_do';
			}
			if (input instanceof HTMLSelectElement) {
				return input.name !== '';
			}
			if (input instanceof HTMLButtonElement) {
				return input.name !== '';
			}

			return true;
		});

		inputs.forEach((input: HTMLInputElement) => {
			if (result.has(input.name)) {
				result.get(input.name).push(input);
			} else {
				result.set(input.name, [input]);
			}
		})

		return result;
	}

}
