import * as CookieConsent from "vanilla-cookieconsent";
import {Extension} from "naja/dist/Naja";
import {Naja} from "naja";
import {Category, CookieConsentConfig, CookieValue, Translation} from "vanilla-cookieconsent";

declare global {
	interface Window {
		dataLayer: any[],
	}
}

export default class CookieConsentExtension implements Extension {

	public initialize(naja: Naja): void {
		window.dataLayer = window.dataLayer || [];

		CookieConsent.run(this.getConfig());
	}

	private getConfig(): CookieConsentConfig {
		return <CookieConsentConfig>{
			autoShow: document.body.getAttribute('data-no-cookie-consent') === null,
			onConsent: (param) => this.processConsent(param.cookie),
			onChange: (param) => this.processConsent(param.cookie),

			categories: {
				necessary: {
					enabled: true,
					readOnly: true,
				},
				analytics: {},
				// marketing: {},
			},
			language: {
				default: 'en',
				autoDetect: 'document',
				translations: {
					en: {
						consentModal: {
							title: 'This website uses cookies',
							description: 'By clicking "Accept All", you consent to their storage on your device, which helps with site navigation, data usage analysis, and our marketing efforts.',
							acceptAllBtn: 'Accept All',
							acceptNecessaryBtn: 'Accept Necessary',
							showPreferencesBtn: 'Show Preferences',
						},
						preferencesModal: {
							title: 'Manage cookie preferences',
							acceptAllBtn: 'Accept All',
							acceptNecessaryBtn: 'Accept Necessary',
							savePreferencesBtn: 'Save Preferences',
							closeIconLabel: 'Close',
							sections: [
								{
									title: 'Functional',
									description: 'They are necessary for the site to work, so it is not possible to turn them off. They are usually set up in response to actions you have taken, such as requesting services related to security settings, logging in, filling out forms, and so on. You can set your browser to block cookies or send notifications about them. Keep in mind that some pages without these files will not work. These cookies do not store any personally identifiable information.',
									linkedCategory: 'necessary',
									cookieTable: {
										headers: {
											name: 'Name',
											duration: 'Duration',
											description: 'Description',
										},
										body: [
											{
												name: 'cc_cookie',
												duration: '6 months',
												description: 'Your cookie settings for this popup.',
											},
											{
												name: 'PHPSESSID',
												duration: '14 days',
												description: 'Generated by PHP language for session identification.',
											}
										]
									}
								},
								{
									title: 'Analytical',
									description: 'They help track the number of visitors and also the source of the traffic, which allows us to improve the performance of the site. With them, we can determine which sites are the most popular and which are not popular, and we can also track how visitors navigate the site. All information that cookies collect is aggregated and anonymous. If you do not enable cookies, we will not know when you visited our site.',
									linkedCategory: 'analytics',
									cookieTable: {
										headers: {
											name: 'Name',
											domain: 'Domain',
											duration: 'Duration',
											description: 'Description',
										},
										body: [
											{
												name: '_ga*',
												domain: 'google.com',
												duration: '2 years',
												description: 'The cookie is associated with Google Analytics services. It serves to identify users based on the allocation of a random number (identifier). It is part of every page request on the website and is used to calculate visitor, session and campaign data for website analytics reports.',
											},
											{
												name: '_gid',
												domain: 'google.com',
												duration: '1 day',
												description: 'This cookie is set by Google Analytics. It stores and updates a unique value for each page visited and is used to count and track page views.',
											}
										]
									}
								},
								// {
								// 	title: 'Marketing',
								// 	description: 'These cookies can be set on our site by advertising partners. They can use them to profile your interests and show you ads on other sites. However, they do not store your personal information directly, but through the unique identifiers of the browser and Internet device. If you don\'t enable them, you\'ll see less targeted promotion on your site.',
								// 	linkedCategory: 'marketing'
								// },
							],
						}
					},
					cs: {
						consentModal: {
							title: 'Tento web používá cookies',
							description: 'Pokud kliknete na „Přijmout vše", poskytnete tím souhlas k jejich ukládání na vašem zařízení, což pomáhá s navigací na stránce, s analýzou využití dat a s našimi marketingovými snahami.',
							acceptAllBtn: 'Přijmout vše',
							acceptNecessaryBtn: 'Odmítnout vše',
							showPreferencesBtn: 'Spravovat nastavení',
						},
						preferencesModal: {
							title: 'Spravovat nastavení cookies',
							acceptAllBtn: 'Přijmout vše',
							acceptNecessaryBtn: 'Odmítnout vše',
							savePreferencesBtn: 'Uložit nastavení',
							closeIconLabel: 'Zavřít',
							sections: [
								{
									title: 'Funkční',
									description: 'Jsou nezbytné k tomu, aby web fungoval, takže není možné je vypnout. Většinou jsou nastavené jako odezva na akce, které jste provedli, jako je požadavek služeb týkajících se bezpečnostních nastavení, přihlašování, vyplňování formulářů atp. Prohlížeč můžete nastavit tak, aby blokoval soubory cookie nebo o nich posílal upozornění. Mějte na paměti, že některé stránky bez těchto souborů nebudou fungovat. Tyto soubory cookie neukládají žádné osobní identifikovatelné informace.',
									linkedCategory: 'necessary',
									cookieTable: {
										headers: {
											name: 'Název',
											duration: 'Platnost',
											description: 'Popis',
										},
										body: [
											{
												name: 'cc_cookie',
												duration: '6 měsíců',
												description: 'Vaše nastavení cookies tohoto popupu.',
											},
											{
												name: 'PHPSESSID',
												duration: '14 dní',
												description: 'Generováno PHP jazykem pro identifikaci session.',
											}
										]
									}
								},
								{
									title: 'Analytické',
									description: 'Pomáhají sledovat počet návštěvníků a také z jakého zdroje provoz pochází, což nám umožňuje zlepšovat výkon stránky. Můžeme s nimi určovat, které stránky jsou nejoblíbenější a které nejsou oblíbené, a také sledovat, jakým způsobem se návštěvníci na webu pohybují. Všechny informace, které soubory cookie shromažďují, jsou souhrnné a anonymní. Pokud soubory cookie nepovolíte, nebudeme vědět, kdy jste navštívili naši stránku.',
									linkedCategory: 'analytics',
									cookieTable: {
										headers: {
											name: 'Název',
											domain: 'Doména',
											duration: 'Platnost',
											description: 'Popis',
										},
										body: [
											{
												name: '_ga*',
												domain: 'google.com',
												duration: '2 roky',
												description: 'Cookie je spojena se službami Google Analytics. Slouží k identifikaci uživatelů na základě přidělení náhodného čísla (identifikátoru). Je součástí každého požadavku na stránku na webu a slouží k výpočtu údajů o návštěvnících, relacích a kampaních pro analytické přehledy webů.',
											},
											{
												name: '_gid',
												domain: 'google.com',
												duration: '1 den',
												description: 'Tento soubor cookie nastavuje Google Analytics. Ukládá a aktualizuje jedinečnou hodnotu pro každou navštívenou stránku a slouží k počítání a sledování zobrazení stránek.',
											}
										]
									}
								},
								// {
								// 	title: 'Marketingové',
								// 	description: 'Tyto soubory cookie mohou na naší stránce nastavovat partneři z reklamy. Mohou je používat na vytváření profilů o vašich zájmech a podle nich vám zobrazovat reklamy i na jiných stránkách. Neukládají ale vaše osobní informace přímo, nýbrž přes jedinečné identifikátory prohlížeče a internetového zařízení. Pokud je nepovolíte, bude se vám zobrazovat na stránkách méně cílená propagace.',
								// 	linkedCategory: 'marketing'
								// },
							],
						}
					}
				}
			},
		};
	}

	private processConsent(cookie: CookieValue): void {
		if (typeof gtag === 'undefined') {
			return;
		}

		const consentAnalytics = cookie.categories.includes('analytics') ? 'granted' : 'denied';
		gtag('consent', 'update', {	analytics_storage: consentAnalytics });
		window.dataLayer.push({ event: "analytics_storage" });

		const consentMarketing = cookie.categories.includes('marketing') ? 'granted' : 'denied';
		gtag("consent", "update", { ad_storage: consentMarketing, ad_user_data: consentMarketing, ad_personalization: consentMarketing });
		window.dataLayer.push({ event: "ad_storage" });
	}

}
