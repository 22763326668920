import FormContext from "../FormContext";
import FormControl from "../components/control/FormControl";
import AbstractFetcher from "./AbstractFetcher";

export default class FormControls extends AbstractFetcher {
	private readonly context: FormContext;
	private readonly instances: Map<string, FormControl>;

	public constructor(context: FormContext) {
		super(context);
		this.context = context;
		this.instances = new Map<string, FormControl>();
	}

	public get(name: string): FormControl {
		return this.instances.get(name) ?? null;
	}

	public forEach(callbackFn: (control: FormControl, name: string) => void): void {
		this.instances.forEach(callbackFn);
	}

	public processSnippet(snippet: Element): void {
		const controls: FormControl[] = [];
		this.fetchGroupedInputs(snippet)
			.forEach((inputs: HTMLInputElement[], name: string) => controls.push(this.create(name, inputs)));

		for (const control of controls) {
			control.initialize();
		}
	}

	public destroySnippet(snippet: Element): void {
		this.fetchGroupedInputs(snippet)
			.forEach((inputs: HTMLInputElement[], name: string) => this.destroy(name));
	}

	private create(name: string, inputs: HTMLInputElement[]): FormControl {
		const control = new FormControl(this.context, name, inputs);

		this.instances.set(name, control);
		this.context.dependencies.add(control);

		return control;
	}

	private destroy(name: string): void {
		if (!this.instances.has(name)) {
			return;
		}

		const control = this.instances.get(name);

		this.instances.delete(name);
		this.context.dependencies.delete(control);

		this.context.groups.destroy(name, control);
	}
}
