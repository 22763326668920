import AbstractComponentExtension from "../AbstractComponentExtension";
import Tooltip from 'bootstrap/js/src/tooltip';

export default class BootstrapTooltipComponentExtension extends AbstractComponentExtension<Tooltip> {

	protected getSelector(): string {
		return '[data-bs-toggle="tooltip"]';
	}

	protected createComponent(el: Element) {
		let config = {};
		let selector = el.getAttribute('data-title-selector');
		if (selector !== null) {
			let titleEl = document.querySelector(selector);
			if (titleEl !== null) {
				config['title'] = titleEl.innerHTML;
				config['html'] = true;
			}
		}

		return Tooltip.getOrCreateInstance(el, config);
	}

	protected destroyComponent(component): void {
		component.dispose();
	}

}
