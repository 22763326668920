import {Naja} from 'naja';
import {Extension} from "naja/dist/Naja";

export default class TransitionExtension implements Extension {
	private static readonly SHOW_DELAY = 300;
	private readonly defaultSelector: string;
	private readonly duration: number;
	private defaultTransitionBlock: Element;
	private timeout: NodeJS.Timeout;

	constructor(defaultSelector: string, duration: number) {
		this.defaultSelector = defaultSelector;
		this.duration = duration;
	}

	public initialize(naja: Naja): void {
		this.defaultTransitionBlock = document.querySelector(this.defaultSelector);

		naja.uiHandler.addEventListener('interaction', (ev) => this.locateBlock(ev));
		naja.addEventListener('start', (ev) => this.showLoaderDelayed(ev));
		naja.addEventListener('complete', (ev) => this.hideLoader(ev));
	}

	private locateBlock({detail}) {
		const transitionBlock = detail.element.closest('.transition-auto');
		detail.options.transitionBlock = transitionBlock || this.defaultTransitionBlock;
		detail.options.transitionDisabled = detail.element.getAttribute('data-transition-disable') !== null;
	}

	private showLoaderDelayed(ev) {
		if (ev.detail.options.transitionDisabled) {
			return;
		}

		this.timeout = setTimeout(() => this.showLoader(ev), TransitionExtension.SHOW_DELAY);
	}

	private showLoader({detail}) {
		if (detail.options.transitionDisabled) {
			return;
		}

		const transitionBlock = detail.options.transitionBlock || this.defaultTransitionBlock;
		transitionBlock.classList.add('transition-out');
		transitionBlock.classList.add('transition-middle');

		this.timeout = null;
	}

	private hideLoader({detail}) {
		if (detail.options.transitionDisabled) {
			return;
		}
		if (detail.payload && detail.payload.redirect) {
			return;
		}

		clearTimeout(this.timeout);

		const transitionBlock = detail.options.transitionBlock || this.defaultTransitionBlock;
		transitionBlock.classList.remove('transition-out');
		transitionBlock.classList.add('transition-in');

		window.setTimeout(() => {
			transitionBlock.classList.remove('transition-middle');
			transitionBlock.classList.remove('transition-in');
		}, this.duration)
	}
}
