import AbstractComponentExtension from "../AbstractComponentExtension";
import GalleryBanner from "./GalleryBanner";


export default class GalleryBannerComponentExtension extends AbstractComponentExtension<GalleryBanner> {

	protected getSelector(): string {
		return '[data-type="gallery-banner"]';
	}

	protected createComponent(el: HTMLElement): GalleryBanner {
		return new GalleryBanner(el);
	}

	protected destroyComponent(component: GalleryBanner): void {
		component.destroy();
	}

}
