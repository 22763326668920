import {Extension} from "naja/dist/Naja";
import {Naja} from "naja";
import {AfterUpdateEvent, BeforeUpdateEvent} from "naja/dist/core/SnippetHandler";

export default class FormAutoSubmitExtension implements Extension {
	private static SEND_DELAY = 300;

	private naja: Naja;
	private timeout: NodeJS.Timeout;

	public initialize(naja: Naja): void {
		this.naja = naja;
		this.handleSubmitLazy = this.handleSubmitLazy.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.initModule(document.querySelector('body'));

		naja.snippetHandler.addEventListener('afterUpdate', (event: AfterUpdateEvent) => {
			this.initModule(event.detail.snippet);
		});
		naja.snippetHandler.addEventListener('beforeUpdate', (event: BeforeUpdateEvent) => {
			this.destroyModule(event.detail.snippet);
		});
	}

	private initModule(snippet: Element) {
		snippet.querySelectorAll('[data-form-auto-submit]').forEach(input => {
			input.addEventListener('input', this.handleSubmitLazy);
			input.addEventListener('change', this.handleSubmitLazy);
		});
	}

	private destroyModule(snippet: Element) {
		snippet.querySelectorAll('[data-form-auto-submit]').forEach(input => {
			input.removeEventListener('input', this.handleSubmitLazy);
			input.removeEventListener('change', this.handleSubmitLazy)
		});
	}

	private handleSubmitLazy(event: Event) {
		if (this.timeout !== null) {
			clearTimeout(this.timeout);
			this.timeout = null;
		}

		let delay: number = FormAutoSubmitExtension.SEND_DELAY;
		if (event.target instanceof Element) {
			let form = event.target.closest('form');
			let delayStr = form.getAttribute('data-form-auto-submit-delay');
			if (delayStr !== null) {
				delay = parseInt(delayStr);
			}
		}

		this.timeout = setTimeout(() => this.handleSubmit(event), delay);
	}

	private handleSubmit(event: Event) {
		if (event.target instanceof Element) {
			let form = event.target.closest('form');
			if (form instanceof HTMLFormElement) {
				let submitButton = form.querySelector('input[type="submit"]');
				if (submitButton instanceof HTMLElement) {
					submitButton.click();
				}
			}
		}
	}

}
