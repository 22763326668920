import FormContext from "../../FormContext";

export default class FormControlMessage {
	private readonly messageErrorClass: string;
	private readonly messageValidClass: string;
	private readonly parent: Element;
	private readonly element: HTMLElement;

	public constructor(context: FormContext, inputs: HTMLInputElement[]) {
		this.messageErrorClass = context.options.messageErrorClass;
		this.messageValidClass = context.options.messageValidClass;
		this.parent = this.findParent(inputs, context.options.messageParentSelector);
		this.element = document.createElement(context.options.messageTag);
		this.element.style.display = 'none';
		this.parent.appendChild(this.element);

		this.processServerErrors();
	}

	public clear(): void {
		this.element.style.display = 'none';
		this.element.innerHTML = '';

		if (this.element.classList.contains(this.messageErrorClass)) {
			this.element.classList.remove(this.messageErrorClass);
		}
		if (this.element.classList.contains(this.messageValidClass)) {
			this.element.classList.remove(this.messageValidClass);
		}
	}

	public setErrorMessage(errorMessage: string) {
		this.element.style.display = null;
		if (this.element.classList.contains(this.messageValidClass)) {
			this.element.classList.remove(this.messageValidClass);
		}
		if (!this.element.classList.contains(this.messageErrorClass)) {
			this.element.classList.add(this.messageErrorClass);
		}
		this.element.innerHTML = errorMessage ?? '';
	}

	public setValidMessage(validMessage: string) {
		this.element.style.display = null;
		if (this.element.classList.contains(this.messageErrorClass)) {
			this.element.classList.remove(this.messageErrorClass);
		}
		if (!this.element.classList.contains(this.messageValidClass)) {
			this.element.classList.add(this.messageValidClass);
		}
		this.element.innerHTML = validMessage;
	}

	private findParent(inputs: HTMLInputElement[], selector: string): Element {
		for (const input of inputs) {
			let parent = input.closest(selector);
			if (parent !== null) {
				return parent;
			}
		}
		return inputs[0].parentElement;
	}

	private processServerErrors(): void {
		const errors = [];
		const elements = this.parent.getElementsByClassName(this.messageErrorClass);
		for (let i = 0; i < elements.length; i++) {
			const element = elements[i];
			const parent = element.parentElement;
			if (parent === this.parent) {
				errors.push(element.innerHTML);
				parent.removeChild(element);
			}
		}
		if (errors.length > 0) {
			const message = errors.filter(error => error.trim() !== '').join(' ');
			if (message !== '') {
				this.element.innerHTML = message;
				this.element.style.display = null;
				this.element.classList.add(this.messageErrorClass);
			}
		}
	}

}
