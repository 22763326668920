import AbstractComponentExtension from "../AbstractComponentExtension";
import L from "leaflet";

L.Icon.Default.imagePath = "/img/leaflet/";

// objekt SMap se natáhne z externího skriptu v šabloně
declare var SMap: any;

export default class CompanyMapComponentExtension extends AbstractComponentExtension<HTMLElement> {

	protected getSelector(): string {
		return '.init-company-map';
	}

	protected createComponent(el: HTMLElement) {
		let data = JSON.parse(el.dataset.data);
		
		if (typeof data['apiKey'] === 'undefined') {
			console.error('API key for Seznam Maps is not set.');
			
			return;
		}
		
		let self = this;
		
		if (data['gpsLat'] && data['gpsLon']) {
			this.showMap(el.id, data, data['gpsLat'], data['gpsLon']);
			
		} else if (data['address'] !== undefined) {
			let geocoder = new SMap.Geocoder(data['address'], function(geocoderResult) {
				let addressResults = geocoderResult.getResults();
				
				if (addressResults[0]['results'][0] !== undefined) {
					let addressData = addressResults[0]['results'][0];
					
					self.showMap(el.id, data, addressData.coords.y, addressData.coords.x);
				} else {
					console.error("Couldn't parse address: " + data['address']);
				}
			});
			
		} else {
			console.error('GPS or full address must be provided.');
		}
		
		return el;
	}

	protected destroyComponent(component): void {
		// TODO
	}
	
	private showMap(mapId: string, data: Object, gpsLat: number, gpsLon: number): void {
		let companyName = data['company'] || null;
		let nearCompanies = data['nearCompanies'] || new Array();
		let apiKey = data['apiKey'];
		
		let map = L.map(mapId).setView([gpsLat, gpsLon], 16);
		
		const options: L.MapOptions = {
			minZoom: 0,
			maxZoom: 19,
			attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
		};
		
		L.tileLayer('https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=' + apiKey, options).addTo(map);
		
		L.marker([gpsLat, gpsLon], {
			title: companyName
		}).addTo(map);
		
		nearCompanies.forEach(function(company) {
			let marker = L.marker([company['gpsLat'], company['gpsLon']], {
				title: company['name']
			}).addTo(map);
			
			marker._icon.style['filter'] = 'hue-rotate(120deg)';
		});
		
		const LogoControl = L.Control.extend({
			options: {
				position: 'bottomleft'
			},
			
			onAdd: function (map) {
				const container = L.DomUtil.create('div');
				const link = L.DomUtil.create('a', '', container);
				
				link.setAttribute('href', 'http://mapy.cz/');
				link.setAttribute('target', '_blank');
				link.innerHTML = '<img src="https://api.mapy.cz/img/api/logo.svg" />';
				L.DomEvent.disableClickPropagation(link);
				
				return container;
			},
		});
		
		new LogoControl().addTo(map);
	}

}
