import {Naja} from 'naja';
import {Extension} from "naja/dist/Naja";
import {AfterUpdateEvent, BeforeUpdateEvent} from "naja/dist/core/SnippetHandler";
import {nanoid} from "nanoid";

export default abstract class AbstractComponentExtension<T> implements Extension {
	protected readonly idAttribute: string = 'data-component-id';
	protected readonly idSize: number = 12;
	protected components: { [key: string]: T } = {}

	public initialize(naja: Naja): void {
		this.initModule(document.querySelector('body'));

		naja.snippetHandler.addEventListener('afterUpdate', (event: AfterUpdateEvent) => {
			this.initModule(event.detail.snippet);
		});
		naja.snippetHandler.addEventListener('beforeUpdate', (event: BeforeUpdateEvent) => {
			this.destroyModule(event.detail.snippet);
		});
		naja.snippetHandler.addEventListener('setDefaults', (event: AfterUpdateEvent) => {
			this.setModuleDefaults(event.detail.snippet);
		});
	}

	public getComponent(el: Element): T {
		return this.components[this.getComponentId(el)];
	}

	protected abstract getSelector(): string;

	protected abstract createComponent(el: Element): T;

	protected abstract destroyComponent(component: T): void;

	protected setComponentDefaults(component: T): void {
	}

	private initModule(snippet: Element): void {
		snippet.querySelectorAll(this.getSelector()).forEach((el) => {
			let id = this.getComponentId(el);
			this.components[id] = this.createComponent(el);
		});
	}

	private setModuleDefaults(snippet: Element): void {
		snippet.querySelectorAll(this.getSelector()).forEach((el) => {
			let id = this.getComponentId(el);
			if (this.components.hasOwnProperty(id)) {
				this.setComponentDefaults(this.components[id]);
			}
		});
	}

	private destroyModule(snippet: Element): void {
		snippet.querySelectorAll(this.getSelector()).forEach((el) => {
			let id = this.getComponentId(el);
			if (this.components.hasOwnProperty(id)) {
				this.destroyComponent(this.components[id]);
				delete this.components[id];
			}
		});
	}

	private getComponentId(el: Element): string {
		return el.id
			|| el.getAttribute(this.idAttribute)
			|| this.createComponentId(el);
	}

	private createComponentId(el: Element): string {
		let id = nanoid(this.idSize);
		el.setAttribute(this.idAttribute, id);

		return id;
	}
}
