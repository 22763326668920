import AbstractComponentExtension from "../AbstractComponentExtension";
import Listing from "./Listing";

export default class ListingComponentExtension extends AbstractComponentExtension<Listing> {

	protected getSelector(): string {
		return '.init-listing';
	}

	protected createComponent(el: Element): Listing {
		return new Listing(el);
	}

	protected destroyComponent(component: Listing): void {
		component.destroy();
	}

}
