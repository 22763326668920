import AbstractComponentExtension from "../AbstractComponentExtension";
import Alert from "bootstrap/js/src/alert";

export default class BootstrapDropdownComponentExtension extends AbstractComponentExtension<Alert> {

	protected getSelector(): string {
		return '.alert';
	}

	protected createComponent(el: Element) {
		return Alert.getOrCreateInstance(el);
	}

	protected destroyComponent(component): void {
		component.dispose();
	}

}
