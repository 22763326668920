import FormControl from "../control/FormControl";
import FormContext from "../../FormContext";
import FormGroupRenderer from "./FormGroupRenderer";
import FormGroupStatus from "./FormGroupStatus";

export default class FormGroup {
	private readonly controls: Set<FormControl>;
	private readonly status: FormGroupStatus;
	private readonly renderer: FormGroupRenderer;

	public constructor(context: FormContext, element: Element, controls: Set<FormControl>) {
		this.controls = new Set<FormControl>();
		this.status = new FormGroupStatus(this.controls);
		this.renderer = new FormGroupRenderer(context, element, this.status);

		this.render = this.render.bind(this);

		this.addControls(controls);
	}

	public initialize(): void {
		this.renderer.renderRequired();
	}

	public invalidate(): void {
		this.status.invalidate();
		this.renderer.renderRequired();
	}

	public render(): void {
		this.status.invalidate();
		this.renderer.render();
	}

	public addControls(controls: Set<FormControl>): void {
		controls.forEach((control: FormControl) => this.addControl(control));
	}

	private addControl(control: FormControl): void {
		control.addEventListener('validate', this.render);
		this.controls.add(control);
	}

	public removeControl(control: FormControl): boolean {
		control.removeEventListener('validate', this.render);
		return this.controls.delete(control);
	}

	public isEmpty(): boolean {
		return this.controls.size === 0;
	}
}
