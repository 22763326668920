import AbstractComponentExtension from "../AbstractComponentExtension";
import Tab from "bootstrap/js/src/tab";

export default class BootstrapTabComponentExtension extends AbstractComponentExtension<Tab> {

	protected getSelector(): string {
		return '[data-bs-toggle="pill"]';
	}

	protected createComponent(el: Element) {
		let options = {};
		
		return Tab.getOrCreateInstance(el, options);
	}

	protected destroyComponent(component): void {
		component.dispose();
	}

}
