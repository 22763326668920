import AbstractComponentExtension from "../AbstractComponentExtension";
import Collapse from 'bootstrap/js/src/collapse';

export default class BootstrapCollapseComponentExtension extends AbstractComponentExtension<Collapse> {

	protected getSelector(): string {
		return '[data-bs-toggle="collapse"]';
	}

	protected createComponent(el: Element) {
		let options = {};

		return Collapse.getOrCreateInstance(el, options);
	}

	protected destroyComponent(component): void {
		component.dispose();
	}

}
