import AbstractCompanyMapItineraryComponentExtension from "./AbstractCompanyMapItineraryComponentExtension";

// objekt SMap se natáhne z externího skriptu v šabloně
declare var SMap: any;

export default class CompanyMapItineraryNoClientComponentExtension extends AbstractCompanyMapItineraryComponentExtension<HTMLElement> {
	
	private el: HTMLElement;
	private suggestResult: Object = null;
	
	protected getSelector(): string {
		return '.init-company-map-itinerary-no-client';
	}

	protected createComponent(el: HTMLElement) {
		this.el = el;
		
		let self = this;
		let inputElementSuggestion = el.querySelector<HTMLInputElement>('input.address-suggestion');
		let button = el.querySelector('button');
		
		button.addEventListener('click', function() {
			let selectedOption = self.getSelectedOption();
			let targetAddressGps = JSON.parse(selectedOption.dataset['gps']);
			
			el.querySelector('.error').classList.add('d-none');
			
			if (self.suggestResult !== null) {
				if (targetAddressGps['lat'] && targetAddressGps['lon']) {
					self.processRoute(targetAddressGps['lat'], targetAddressGps['lon']);
					
				} else {
					let geocoder = new SMap.Geocoder(selectedOption.value, function(geocoderResult) {
						let addressResults = geocoderResult.getResults();
						
						if (addressResults[0]['results'][0] !== undefined) {
							let addressData = addressResults[0]['results'][0];
							
							self.processRoute(addressData.coords.y, addressData.coords.x)
						} else {
							el.querySelector('.error').classList.remove('d-none');
						}
					});
				}
			}
		});
		
		inputElementSuggestion.addEventListener('blur', function() {
			if (this.value === '') {
				button.disabled = true;
			}
		});
		
		let suggest = this.createSuggest(inputElementSuggestion);
		
		suggest.addListener('suggest', function(suggestResult) {
			self.suggestResult = suggestResult;
			
			inputElementSuggestion.value = self.parseMapySuggestedAddress(suggestResult);
			
			button.disabled = false;
		});
		
		return el;
	}

	protected destroyComponent(component): void {
		// TODO
	}
	
	private processRoute(gpsLat, gpsLon): void {
		let startCoords = SMap.Coords.fromWGS84(this.suggestResult['data']['longitude'], this.suggestResult['data']['latitude']);
		let endCoords = SMap.Coords.fromWGS84(gpsLon, gpsLat);
		
		let smapUrl1 = new SMap.URL.Route().addStart(startCoords).addDestination(endCoords);
		let resultUrl = smapUrl1.toString();
		
		window.open(resultUrl, '_blank');
	}
	
	private getSelect(): HTMLSelectElement {
		return this.el.querySelector<HTMLSelectElement>('select.select-address');
	}
	
	private getSelectedOption(): HTMLOptionElement {
		let selectElement = this.getSelect();
		
		return selectElement.options[selectElement.selectedIndex];
	}
	
}
