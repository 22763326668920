import FormControlMessage from "./FormControlMessage";
import FormControlRuleCollection from "./FormControlRuleCollection";
import FormContext from "../../FormContext";
import FormControlValueType from "./FormControlValueType";
import AbstractEventTarget from "../../events/AbstractEventTarget";

export default class FormControl extends AbstractEventTarget {
	private readonly context: FormContext;
	public readonly name: string;
	private readonly inputs: HTMLInputElement[];
	private readonly message: FormControlMessage;
	public readonly rules: FormControlRuleCollection;

	private timeoutKeyboard: NodeJS.Timeout;
	private timeoutUpdateValue: NodeJS.Timeout;
	private value: FormControlValueType;
	private valueDirty: boolean = true;
	private valid: boolean = null;
	private validDirty: boolean = true;
	private required: boolean = null;
	private requiredDirty: boolean = true;

	public constructor(context: FormContext, name: string, inputs: HTMLInputElement[]) {
		super();
		this.context = context;
		this.name = name;
		this.inputs = inputs;
		this.message = new FormControlMessage(context, inputs);
		this.rules = FormControlRuleCollection.from(inputs);

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
		this.handleInputKeyUp = this.handleInputKeyUp.bind(this);

		for (const input of inputs) {
			input.addEventListener('change', this.handleInputChange);
			input.addEventListener('blur', this.handleInputChange);
			input.addEventListener('keydown', this.handleInputKeyDown);
			input.addEventListener('keyup', this.handleInputKeyUp);
		}
	}

	public initialize(): void {
		this.updateValue();
		this.processToggles();
		this.processRequired();
	}

	public invalidate(): void {
		console.debug("FormControl '" + this.name + "' invalidate");
		const wasValid = this.valid;
		this.valid = null;
		this.validDirty = true;
		this.requiredDirty = true;

		if (wasValid === false) {
			this.processValidation();
		} else {
			this.processRequired();
		}

		this.processToggles();
	}

	public isRequired() {
		while (this.requiredDirty) {
			this.processRequired();
		}
		return this.required;
	}

	public isDisabled(): boolean {
		for (const input of this.inputs) {
			if (input.disabled) {
				return true;
			}
		}
		return false;
	}

	public isValid(): boolean {
		while (this.valueDirty) {
			this.clearTimeoutUpdateValue();
			this.updateValue();
		}
		while (this.validDirty) {
			this.processValidation();
		}

		return this.valid;
	}

	public getFirstInput(): HTMLInputElement {
		return this.inputs.length > 0 ? this.inputs[0] : null;
	}

	public getValue(): FormControlValueType {
		while (this.valueDirty) {
			this.clearTimeoutUpdateValue();
			this.updateValue();
		}
		return this.value;
	}

	public dispatchEvent(event: Event): boolean {
		let result = super.dispatchEvent(event);
		for (const input of this.inputs) {
			result = result && input.dispatchEvent(new CustomEvent(event.type + 'FormElement'));
		}
		return result;
	}

	private handleInputChange(event: Event): void {
		console.debug("FormControl '" + this.name + "' event", event);
		this.valueDirty = true;
		this.clearTimeoutUpdateValue();
		this.timeoutUpdateValue = setTimeout(() => this.updateValue(true), this.context.options.waitUpdateValue);
	}

	private handleInputKeyDown(event: KeyboardEvent): void {
		if (!FormControl.isSpecialKey(event)) {
			this.clearTimeoutKeyboard();
		}
	}

	private handleInputKeyUp(event: KeyboardEvent): void {
		if (event.key !== 'Tab') {
			this.clearTimeoutKeyboard();
			this.timeoutKeyboard = setTimeout(() => this.handleInputChange(event), this.context.options.waitKeyboard);
		}
	}

	private clearTimeoutKeyboard(): void {
		if (this.timeoutKeyboard) {
			clearTimeout(this.timeoutKeyboard);
		}
	}

	private clearTimeoutUpdateValue(): void {
		if (this.timeoutUpdateValue) {
			clearTimeout(this.timeoutUpdateValue);
		}
	}

	private updateValue(validate: boolean = false): void {
		let value = this.fetchEffectiveValue();
		if (this.value !== value) {
			this.value = value;
			this.valueDirty = false;
			this.validDirty = true;
			this.dispatchEvent(new CustomEvent('change', {detail: {sender: this}}));
			if (validate) {
				this.processValidation();
				this.processToggles();
			}
			this.context.dependencies.invalidate(this.name);
		} else {
			this.valueDirty = false;
		}
	}

	private processValidation(): void {
		console.debug("FormControl '" + this.name + "' validate value =", this.value);

		this.message.clear();

		let validationResult = this.valid;
		let errorMessage = null;
		let required = false;

		if (this.rules !== null) {
			[validationResult, errorMessage, required] = this.rules.getStatus(this.context, this);
			this.message.setErrorMessage(errorMessage);
		} else {
			validationResult = true;
		}
		if (this.valid !== validationResult) {
			this.valid = validationResult;
			this.processDisplayValid();
		}
		if (this.required !== required) {
			this.required = required;
			this.processDisplayRequired();
		}

		this.validDirty = false;
		this.requiredDirty = false;

		this.dispatchEvent(new CustomEvent('validate', {detail: {sender: this}}));
	}

	private processToggles(): void {
		if (this.rules === null) {
			return;
		}

		console.debug("FormControl '" + this.name + "' toggles value =", this.value);
		this.rules.getToggles(this.context, this)
			.forEach((show: boolean, selector: string) => {
				document.querySelectorAll(selector).forEach((elem: HTMLElement) => {
					elem.style.display = show ? null : 'none';
				});
			})
	}

	private processRequired(): void {
		if (this.rules === null) {
			this.required = false;
			this.requiredDirty = false;
			return;
		}
		if (this.requiredDirty) {
			const [validationResult, errorMessage, required] = this.rules.getStatus(this.context, this);
			this.required = required;
			this.requiredDirty = false;
			this.processDisplayRequired();
		}
	}

	private processDisplayValid(): void {
		let options = this.context.options;
		for (const input of this.inputs) {
			if (input.classList.contains(options.controlErrorClass)) {
				input.classList.remove(options.controlErrorClass);
			}
			if (input.classList.contains(options.controlValidClass)) {
				input.classList.remove(options.controlValidClass);
			}

			let showError = this.context.showError || input.matches(options.controlShowErrorSelector);
			if (showError && !this.valid && !input.matches(options.controlDisableErrorSelector)) {
				input.classList.add(options.controlErrorClass);
			}
			let showValid = this.context.showValid || input.matches(options.controlShowValidSelector);
			if (showValid && this.valid && !input.matches(options.controlDisableValidSelector)) {
				input.classList.add(options.controlValidClass);
			}
		}
	}

	private processDisplayRequired(errorMessage: string = null): void {
		let options = this.context.options;
		for (const input of this.inputs) {
			if (input.classList.contains(options.controlRequiredClass)) {
				input.classList.remove(options.controlRequiredClass);
			}
			if (this.required) {
				input.classList.add(options.controlRequiredClass);
			}
		}
	}

	private fetchEffectiveValue(): FormControlValueType {
		let value = this.fetchValue();
		let input = this.inputs[0];

		return input.getAttribute('data-nette-empty-value') === value ? '' : value;
	}

	private fetchValue(): FormControlValueType {
		if (this.inputs.length === 0) {
			return null;
		}

		let input = this.inputs[0];
		if (this.inputs.length === 1) {
			if (input.type === 'file') {
				return input.files || input.value;

			} else if (input.tagName.toLowerCase() === 'select' && input instanceof HTMLSelectElement) {
				let index = input.selectedIndex;
				let options = input.options;

				if (input.type === 'select-one') {
					return index < 0 ? null : options[index].value;
				}

				let values: string[] = [];
				for (let i = 0; i < options.length; i++) {
					if (options[i].selected) {
						values.push(options[i].value);
					}
				}
				return values;

			} else if (input.type === 'checkbox') {
				return input.checked;

			} else if (input.tagName.toLowerCase() === 'textarea') {
				return input.value.replace("\r", '')

			} else {
				return input.value.replace("\r", '').replace(/^\s+|\s+$/g, '')
			}
		}

		if (input.type === 'radio') {
			for (const radio of this.inputs) {
				if (radio.checked) {
					return radio.value;
				}
			}
		}

		if (input.name.substr(-2) === '[]') {
			let values: string[] = [];
			for (const input of this.inputs) {
				let value = input.value;
				if (input.type === 'checkbox' && input.checked) {
					values.push(value);
				} else if (input.type !== 'checkbox' && value !== '') {
					values.push(value);
				}
			}

			return values;
		}

		return null;
	}

	private static isSpecialKey(event: KeyboardEvent): boolean {
		if (!(event instanceof KeyboardEvent)) {
			return false;
		}

		return event.key === 'Shift'
			|| event.key === 'Tab'
			|| event.key === 'Escape'
			|| event.key === 'Control'
			|| event.key === 'Pause'
			|| event.key === 'Alt'
			|| event.key === 'ContextMenu'
			|| event.key === 'Insert'
			|| event.key === 'Home'
			|| event.key === 'End'
			|| event.key.startsWith('Page')
			|| event.key.startsWith('Arrow')
			|| event.key.endsWith('Lock')
			|| !!event.key.match('F[0-9]+');
	}

}
