import AbstractFetcher from "./AbstractFetcher";
import FormContext from "../FormContext";
import FormGroup from "../components/group/FormGroup";
import FormControl from "../components/control/FormControl";

export default class FormGroups extends AbstractFetcher {
	private readonly context: FormContext;
	private readonly instances: Map<string, FormGroup>;

	public constructor(context: FormContext) {
		super(context);
		this.context = context;
		this.instances = new Map<string, FormGroup>();
	}

	public get(name: string): FormGroup {
		return this.instances.get(name) ?? null;
	}

	public processSnippet(snippet: Element): void {
		const groups: FormGroup[] = [];
		if (snippet.matches(this.context.options.groupSelector)) {
			groups.push(this.create(snippet));
		} else {
			snippet.querySelectorAll(this.context.options.groupSelector)
				.forEach((groupElement: Element) => groups.push(this.create(groupElement)));
		}
		for (const group of groups) {
			group.initialize();
		}
	}

	private create(element: Element): FormGroup {
		const inputs = this.fetchGroupedInputs(element);
		const names = Array.from(inputs.keys());

		let group: FormGroup = null;
		const controls = new Set<FormControl>();
		for (const name of names) {
			if (this.instances.has(name)) {
				group = this.instances.get(name);
			}
			const control = this.context.controls.get(name);
			if (control !== null) {
				controls.add(control);
			}
		}

		if (group === null) {
			group = new FormGroup(this.context, element, controls);
		} else {
			group.addControls(controls);
		}
		for (const name of names) {
			this.instances.set(name, group);
		}

		return group;
	}

	public destroy(name: string, control: FormControl = null) {
		if (!this.instances.has(name)) {
			return;
		}

		const group = this.instances.get(name);
		if (control !== null) {
			group.removeControl(control);
		}
		if (group.isEmpty()) {
			this.instances.delete(name);
		}
	}
}
