import AbstractComponentExtension from "../AbstractComponentExtension";
import SwiperSlider from "./SwiperSlider";


export default class SwiperSliderComponentExtension extends AbstractComponentExtension<SwiperSlider> {

	protected getSelector(): string {
		return '[data-type="swiper-slider"]';
	}

	protected createComponent(el: HTMLElement): SwiperSlider {
		return new SwiperSlider(el);
	}

	protected destroyComponent(component: SwiperSlider): void {
		component.destroy();
	}

}
