import FormContext from "../../FormContext";
import FormGroupStatus from "./FormGroupStatus";

export default class FormGroupRenderer {
	private context: FormContext;
	private element: Element;
	private status: FormGroupStatus;

	public constructor(context: FormContext, element: Element, status: FormGroupStatus) {
		this.context = context;
		this.element = element;
		this.status = status;
	}

	public render(): void {
		this.renderValidation();
		this.renderRequired();
	}

	public renderValidation(): void {
		const options = this.context.options;
		if (this.element.classList.contains(options.groupValidClass)) {
			this.element.classList.remove(options.groupValidClass);
		}
		if (this.element.classList.contains(options.groupErrorClass)) {
			this.element.classList.remove(options.groupErrorClass);
		}

		if (this.context.showError && this.status.isError()) {
			this.element.classList.add(options.groupErrorClass);
		} else if (this.context.showValid && this.status.isValid() && !this.status.isError()) {
			this.element.classList.add(options.groupValidClass);
		}
	}

	public renderRequired(): void {
		const options = this.context.options;
		if (this.element.classList.contains(options.groupRequiredClass)) {
			this.element.classList.remove(options.groupRequiredClass);
		}
		if (this.status.isRequired()) {
			this.element.classList.add(options.groupRequiredClass);
		}
	}
}
