import {Naja} from 'naja';
import {Extension} from "naja/dist/Naja";

import {AfterUpdateEvent, BeforeUpdateEvent} from "naja/dist/core/SnippetHandler";
import FormOptions from "../forms/FormOptions";
import FormContext from "../forms/FormContext";
import FormValidator from "../forms/FormValidator";

declare global {
	interface Window {
		formContexts: WeakMap<HTMLFormElement, FormContext>,
		formOptions: FormOptions,
		formValidator: FormValidator,
	}
}

export default class FormValidationExtension implements Extension {
	private readonly options: FormOptions = {
		formDisableValidationSelector: '.no-live-validation',
		formEnableShowErrorSelector: 'form',
		formEnableShowValidSelector: '.show-valid',
		formShowAllErrors: true,

		groupSelector: '.form-group',
		groupErrorClass: 'is-invalid',
		groupValidClass: 'is-invalid',
		groupRequiredClass: 'is-required',

		messageParentSelector: '.form-group',
		messageErrorClass: 'invalid-feedback',
		messageValidClass: 'valid-feedback',
		messageTag: 'div',
		messageIdPostfix: '_message',

		controlSelector: 'input, select, textarea, button',
		controlDisableValidationSelector: '.no-live-validation',
		controlDisableErrorSelector: '.no-show-error',
		controlDisableValidSelector: '.no-show-valid',
		controlShowErrorSelector: '.show-error',
		controlShowValidSelector: '.show-valid',
		controlErrorClass: 'is-invalid',
		controlValidClass: 'is-valid',
		controlRequiredClass: 'is-required',

		waitKeyboard: 200,
		waitUpdateValue: 20,
		focusScreenOffsetY: 0,
	};

	public constructor(options?: FormOptions) {
		Object.assign(this.options, options);

		window.formContexts = new WeakMap<HTMLFormElement, FormContext>();
		window.formOptions = this.options;
		window.formValidator = new FormValidator();
	}

	public initialize(naja: Naja): void {
		naja.formsHandler.netteForms = this;
		naja.snippetHandler.addEventListener('beforeUpdate', (event: BeforeUpdateEvent) => {
			const snippet = event.detail.snippet;
			if (snippet.tagName === 'form') {
				this.destroyForm(<HTMLFormElement>snippet);
			}

			const forms = snippet.querySelectorAll('form');
			for (let i = 0; i < forms.length; i++) {
				this.destroyForm(forms.item(i));
			}

			let form = snippet.closest('form');
			if (form instanceof HTMLFormElement) {
				window.formContexts.get(form).destroySnippet(snippet);
			}
		});
		naja.snippetHandler.addEventListener('afterUpdate', (event: AfterUpdateEvent) => {
			const snippet = event.detail.snippet;

			let form = snippet.closest('form');
			if (form instanceof HTMLFormElement) {
				window.formContexts.get(form).processSnippet(snippet);
			}
		});
	}

	public initForm(form: HTMLFormElement): void {
		if (form.id === null) {
			console.error('Form does not have id, skipping...', form);
			return;
		}
		if (form.matches(this.options.formDisableValidationSelector)) {
			return;
		}

		console.debug('Form initialize', form.id);
		window.formContexts.set(form, new FormContext(this.options, form))
	}

	public validateForm(element: Element): boolean {
		const form = this.getForm(element);
		console.debug('Form validate', element, form);

		if (form === null) {
			return false;
		}

		return window.formContexts.get(form).isValid(element);
	}

	private destroyForm(form: HTMLFormElement): void {
		console.debug('Form destroy', form.id);
		window.formContexts.delete(form);
	}

	private getForm(element: Element): HTMLFormElement {
		if (element instanceof HTMLFormElement) {
			return element;
		}

		return element['form'] ?? element.closest('form');
	}

}
