import AbstractComponentExtension from "../AbstractComponentExtension";
import Carousel from 'bootstrap/js/src/carousel';

export default class BootstrapCarouselComponentExtension extends AbstractComponentExtension<Carousel> {

	protected getSelector(): string {
		return '[data-bs-ride="carousel"]';
	}

	protected createComponent(el: Element) {
		let options = {};

		return Carousel.getOrCreateInstance(el, options);
	}

	protected destroyComponent(component): void {
		component.dispose();
	}

}
